import React, { useMemo, useState } from 'react';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Grid, Link } from '@mui/material';
import clsx from 'clsx';

import { CreateUpdateRuleModal, ModalComponent, NSelect, SadStates, WidgetLoadingState, WidgetNoResultsState } from 'components';
import { useRuleExecutionLog } from 'contexts';
import { CURRENCY_TYPE, CURRENCY_TYPE_LABEL, MODAL_CONTENT_TYPE, QUOTE_RULE_EXECUTION_FILTER_OPTIONS, RULE_EXECUTION_FILTER } from 'enums';
import { formatValue, orderPriceRulesByPriority, sortOptions } from 'helpers';
import { usePricingRulesEditorConfigQuery } from 'services';
import { ActionLog, ActionType, ContextChange, ContextValueType, QuoteExecutionLogData, Rule, Ruleset, RulesetExecutionLog } from 'types';

import { useStyles } from './RuleExecutionLog.css';

type RuleExecutionLogProps = {
  ruleExecutionLog: RulesetExecutionLog<QuoteExecutionLogData>,
  className?: string;
};

export const RuleExecutionLog = ({ruleExecutionLog, className} : RuleExecutionLogProps) => {
  const classes = useStyles();

  const { modalContent, setModalContent, setSelectedRule, setOrderedRuleset, selectedRule, setRuleEditorConfiguration } = useRuleExecutionLog();

  const {rule_action_logs, ruleset, triggered_rules} = ruleExecutionLog || {};
  const orderedRuleset = ruleset ? orderPriceRulesByPriority(ruleset) : null;
  const [ isModalOpen, setIsModalOpen ] = useState(false);

  const { isError, isLoading, isSuccess, data } = usePricingRulesEditorConfigQuery();
  const { data: ruleEditorConfiguration } = data || {};
  const [ orderingFilter, setOrderingFilter ] = useState(RULE_EXECUTION_FILTER.ALL);

  const operandMap: Map<string, string> = useMemo(() => {
    return (ruleEditorConfiguration?.operands ?? []).reduce((map, operand) => {
      map.set(operand.name, operand.display_name);
      return map;
    }, new Map<string, string>());

  }, [ruleEditorConfiguration]);

  const getSetterAction = (contextChange: ContextChange | null, currency: ContextValueType): string => {
    if (!contextChange) {
      return '';
    }

    const fieldName = contextChange.field_name || '';
    const operandLabel = operandMap.get(fieldName) || fieldName;
    const formattedValue = formatValue(contextChange.new_value);

    const isPriceField = fieldName.toLowerCase().includes('price');
    const currencyPrefix = isPriceField
      ? `${CURRENCY_TYPE_LABEL[currency as CURRENCY_TYPE]}`
      : '';

    return `Set ${operandLabel} to ${currencyPrefix}${formattedValue}`;
  };

  const getActionDescription = (actionLog: ActionLog) => {
    if (actionLog === null) {
      return '';
    }

    switch (actionLog.action_type) {
      case ActionType.SETTER:
        return getSetterAction(actionLog.context_change, ruleExecutionLog.context.currency);
      case ActionType.STOP_RULE_GROUP_EXECUTION:
        return 'Rule group execution ended';
      case ActionType.STOP_RULESET_EXECUTION:
        return 'Rule execution ended';
      case ActionType.STOP_RULESET_EXECUTION_NO_REPLY:
        return 'No Reply';
      case ActionType.STOP_RULESET_EXECUTION_REJECT:
        return 'Reject';
      default:
        return 'Unknown Action';
    }
  };

  const getTriggredInfo = (rule: Rule) => {
    const isTriggered = triggered_rules.includes(rule.id);
    const logs = rule_action_logs?.[rule.id] || [];

    const triggredIcon = isTriggered ? faCheckCircle : faTimesCircle;
    let actionDescription = '/';

    if (isTriggered) {
      actionDescription = logs.length > 0
        ? logs.map((log: ActionLog) => getActionDescription(log)).join(', ')
        : 'No rule action logs available';
    }
    return (
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={7}>{actionDescription}</Grid>
        <Grid item xs={5} className={classes.alignRight}><FontAwesomeIcon icon={triggredIcon} className={clsx({
          [classes.icon]: true,
          [classes.triggered]: isTriggered,
          [classes.notTriggered]: !isTriggered,
        })}/>
        </Grid>
      </Grid>
    );
  };

  const filteredRuleset: Ruleset = useMemo(() => {
    if (!orderedRuleset) {
      return [];
    }
    if (orderingFilter === RULE_EXECUTION_FILTER.EXECUTED) {
      return orderedRuleset.filter(rule =>
        triggered_rules.some(item => (item) === rule.id)
      );
    }
    return orderedRuleset;
  }, [ orderedRuleset, orderingFilter, triggered_rules ]);

  const handleLinkClick = (rule: Rule) => {
    setIsModalOpen(true);
    setModalContent(MODAL_CONTENT_TYPE.RULE);
    setOrderedRuleset(orderedRuleset);
    setRuleEditorConfiguration(ruleEditorConfiguration);
    setSelectedRule(rule);
  };

  return (
    <>
      <SadStates
        states={[
          {
            when: orderedRuleset === null,
            render: () => <WidgetNoResultsState message='No rules available.' />,
          },
          {
            when: isSuccess && Object.keys(ruleEditorConfiguration).length === 0,
            render: () => <WidgetNoResultsState message='No rules editor config available.' />,
          },
          {
            when: isError,
            render: () => <WidgetNoResultsState message='Something went wrong when trying to get rules editor config.' />,
          },
        ]}>
        <Grid container spacing={1} className={clsx(classes.container, classes.secondaryText)}>
          <Grid item xs={4}>Name</Grid>
          <Grid item xs={4}>Action</Grid>
          <Grid item xs={4} className={classes.alignRight}>
            <NSelect
              options={sortOptions(QUOTE_RULE_EXECUTION_FILTER_OPTIONS)}
              onChange={(value) => {
                setOrderingFilter(value as RULE_EXECUTION_FILTER);
              }}
              selectedValues={orderingFilter}
              className={classes.selectItem}
              variant='standard'/>
          </Grid>
        </Grid>
        <Divider />
        <SadStates
          states={[
            {
              when: isLoading,
              render: () => <WidgetLoadingState />,
            },
          ]}>
          <Grid className={clsx(classes.scrollableContent, className)}>
            {filteredRuleset?.length ? (
              filteredRuleset.map((rule: Rule) => (
                <div key={rule.id}>
                  <Grid container spacing={1} className={classes.container}>
                    <Grid item xs={4} className={classes.text}>
                      <Link onClick={() => handleLinkClick(rule)} className={classes.link} underline='none'>
                        {rule.name}
                      </Link>
                    </Grid>
                    <Grid item xs={8}>
                      {getTriggredInfo(rule)}
                    </Grid>
                  </Grid>
                  <Divider />
                </div>
              ))
            ) : (
              <WidgetNoResultsState message='No executed rules available.' />
            )}
          </Grid>
        </SadStates>
      </SadStates>
      <ModalComponent
        message={`Show Rule \u2022 ${selectedRule?.name ?? ''}`}
        isOpen={modalContent === MODAL_CONTENT_TYPE.RULE && isModalOpen}
        modalWidth='md'
        onCancel={() => setIsModalOpen(false)}
        disableEnforceFocus={true}>
        <Grid container spacing={1} className={classes.rowContainer}>
          <CreateUpdateRuleModal rules={orderedRuleset} rule={selectedRule} ruleEditorConfiguration={ruleEditorConfiguration} onCancel={() => setSelectedRule(null)} />
        </Grid>
      </ModalComponent>
    </>
  );
};
