import axios, { AxiosResponse } from 'axios';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { API_URL } from '../config';

import { GlobalRule, Opts, Ruleset } from 'types';

const GLOBAL_RULE_API_URL = '/v1/global-rule';
const GLOBAL_PRICE_RULE_QUERY_KEY = 'global-price-rule';
const GLOBAL_MAX_BUY_RULE_QUERY_KEY = 'global-max-buy-rule';

const UNKNOWN_ERROR_MESSAGE = 'An unknown error occured. Please contact support or try again later.';

export const api = {
  globalRules: {
    getPricePredictionGlobalRuleset: () => axios.get(`${API_URL}${GLOBAL_RULE_API_URL}/price-prediction-ruleset/`),
    updatePricePredictionGlobalRuleset: ({ ruleset }: {ruleset: Ruleset}) =>
      axios.patch(`${API_URL}${GLOBAL_RULE_API_URL}/price-prediction-ruleset/`, { ruleset }),
    getMaxBuyPricePredictionGlobalRuleset: () => axios.get(`${API_URL}${GLOBAL_RULE_API_URL}/max-buy-price-prediction-ruleset/`),
    updateMaxBuyPricePredictionGlobalRuleset: ({ ruleset }: {ruleset: Ruleset}) =>
      axios.patch(`${API_URL}${GLOBAL_RULE_API_URL}/max-buy-price-prediction-ruleset/`, { ruleset }),
  }
};

export const useGetPricePredictionGlobalRulesetQuery = () => {
  return useQuery<AxiosResponse<GlobalRule>>([GLOBAL_PRICE_RULE_QUERY_KEY], () => api.globalRules.getPricePredictionGlobalRuleset());
};

export const useGetMaxBuyPricePredictionGlobalRulesetQuery = () => {
  return useQuery<AxiosResponse<GlobalRule>>([GLOBAL_MAX_BUY_RULE_QUERY_KEY], () => api.globalRules.getMaxBuyPricePredictionGlobalRuleset());
};

export const useUpdatePricePredictionGlobalRuleset = (queryClient: QueryClient, opts: Opts) =>
  useMutation(api.globalRules.updatePricePredictionGlobalRuleset, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(GLOBAL_PRICE_RULE_QUERY_KEY);
    },
    onError: () => {
      if (opts.onError) {
        opts.onError();
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useUpdateMaxBuyPricePredictionGlobalRuleset = (queryClient: QueryClient, opts: Opts) =>
  useMutation(api.globalRules.updateMaxBuyPricePredictionGlobalRuleset, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(GLOBAL_MAX_BUY_RULE_QUERY_KEY);
    },
    onError: () => {
      if (opts.onError) {
        opts.onError();
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });