export enum INTEGRATION_TYPES {
    BLUE_YONDER,
    TRANSPLACE,
    QQT_API,
    TMS_3G,
    EMERGE_TMS,
    GREENSCREENS,
    TMC,
    SOFTMODAL,
    LOADSMART,
    MAX_BUY,
    E2OPEN,
  }

export const INTEGRATION_TYPES_LABEL: Record<number, string> = {
  [INTEGRATION_TYPES.BLUE_YONDER]: 'Blue Yonder',
  [INTEGRATION_TYPES.TRANSPLACE]: 'Transplace',
  [INTEGRATION_TYPES.QQT_API]: 'QQT API',
  [INTEGRATION_TYPES.TMS_3G]: '3G TMS',
  [INTEGRATION_TYPES.EMERGE_TMS]: 'Emerge TMS',
  [INTEGRATION_TYPES.GREENSCREENS]: 'Greenscreens',
  [INTEGRATION_TYPES.TMC]: 'TMC',
  [INTEGRATION_TYPES.SOFTMODAL]: 'Softmodal',
  [INTEGRATION_TYPES.LOADSMART]: 'Loadsmart',
  [INTEGRATION_TYPES.MAX_BUY]: 'Max Buy',
  [INTEGRATION_TYPES.E2OPEN]: 'e2open',
};