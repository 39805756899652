import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Grid } from '@material-ui/core';
import { FieldArray, FormikErrors } from 'formik';

import {
  FormWrapper,
  InputField,
  PageLoadingState,
  RadioButtonField,
  SadStates,
  SelectField,
} from 'components';
import { EQUIPMENT_LABEL, AWARD_RATE_TYPE_LABEL, STOP_ACTIVITY_TYPES } from 'enums';
import { useAddAwardLaneToAwardMutation, useGetEquipmentTypesQuery, useGetAwardLaneById, useUpdateAwardLaneMutation } from 'services';
import { AwardLaneForm, Option, Params, Stop } from 'types';
import { getAddAwardLaneToAwardValidationSchema, getUpdateAwardLaneValidationSchema } from 'validations';

import { useStyles } from './AwardLaneUpsertForm.css';
import { AwardLaneStopDisplay } from './AwardLaneStopDisplay';

type AwardLaneUpsertFormProps = {
  onCancel: () => void,
};

export const AwardLaneUpsertForm = ({ onCancel }: AwardLaneUpsertFormProps) => {
  const classes = useStyles();

  const { laneId, awardId: awardId } = useParams<Params>();
  const queryClient = useQueryClient();

  const { isLoading: isLaneLoading, data: laneData, refetch: refetchLane} = useGetAwardLaneById(Number(awardId), Number(laneId));
  const { data: lane } = laneData || {};
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(true);

  const { isLoading: isLoadingEquipment, data: equipmentData } = useGetEquipmentTypesQuery();
  const equipment = equipmentData?.data?.map((eq) => {
    return { id: eq.id, label: eq.type };
  }) || [];

  const { mutate: addAwardLaneToAward, isLoading: isCreatingLane } = useAddAwardLaneToAwardMutation(queryClient, {
    onSuccess: () => {
      onCancel();
      toast.success('Lane successfully added.');
    },
    onError: (error) => {
      if (error?.response?.data) {
        toast.error(error.response.data.error);
      }
    }
  });

  const { mutate: updateAwardLane, isLoading: isEditingLane } = useUpdateAwardLaneMutation(queryClient, {
    onSuccess: () => {
      onCancel();
      refetchLane();
      toast.success('Lane successfully edited.');
    },
    onError: (error) => {
      if (error?.response?.data) {
        toast.error(error.response.data.error);
      }
    }
  });

  const getDefaultRateType = () => {
    if (lane?.line_haul) {
      return 'line_haul';
    } else if (lane?.rpm) {
      return 'rpm';
    } else {
      return 'flat_rate';
    }
  };

  const getEquipmentId = (equipmentId: number) => {
    const equipmentOption = equipment.find((e: Option) => e.id === equipmentId);
    if (equipmentOption?.label === lane?.equipment_type) {
      return lane?.equipment;
    }
    return equipmentId;
  };

  const initialValues = useMemo(() => ({
    equipment: lane?.base_equipment_id || equipment.find((e: Option) => e.label === EQUIPMENT_LABEL.DRY_VAN)?.id,
    estimated_miles: lane?.estimated_miles || '',
    estimated_volume: lane?.estimated_volume || null,
    rate_type: getDefaultRateType(),
    line_haul: lane?.line_haul || '',
    rpm: lane?.rpm || '',
    flat_rate: lane?.flat_rate || '',
    max_rate: lane?.max_rate || null,
    min_rate: lane?.min_rate || null,
    lane: {
      stops: lane ? null : [
        { id: 1, activity_type: STOP_ACTIVITY_TYPES.PICKUP, address: '', facility: '', drop_trailer: false },
        { id: 2, activity_type: STOP_ACTIVITY_TYPES.DELIVERY, address: '', facility: '', drop_trailer: false },
      ],
    },
  }), [ lane, equipment ]);

  const validationSchema = useMemo(() => (
    laneId ? getUpdateAwardLaneValidationSchema() : getAddAwardLaneToAwardValidationSchema()
  ), [laneId]);

  const handleOnSubmit = (values: AwardLaneForm) => {
    const payload = {
      equipment: getEquipmentId(values.equipment),
      estimated_miles: values.estimated_miles ? values.estimated_miles : null,
      estimated_volume: values.estimated_volume ? values.estimated_volume : null,
      line_haul: values.line_haul || null,
      rpm: values.rpm || null,
      flat_rate: values.flat_rate || null,
      max_rate: values.max_rate ? values.max_rate : null,
      min_rate: values.min_rate ? values.min_rate : null
    };

    laneId ? updateAwardLane(
      {
        awardId: Number(awardId),
        awardLaneId: lane.id,
        payload,
      }
    ) :
      addAwardLaneToAward(
        {
          awardId: Number(awardId),
          payload: {
            ...payload,
            lane: {
              stops: values.lane.stops
            }
          }
        }
      );
    setIsSubmitting(false);
  };

  return (
    <SadStates states={[
      {
        when: isLaneLoading || isLoadingEquipment,
        render: () => <PageLoadingState/>
      },
    ]}>
      <FormWrapper<AwardLaneForm>
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={isSubmitting}
        isActionInProgress={isEditingLane || isCreatingLane || isLoadingEquipment}
        primaryButtonLabel={'Save changes'}
        enableReinitialize={false}
        onCancel={onCancel}
        onSubmit={handleOnSubmit}>
        {({ errors, values, setFieldValue, setValues }) => (
          <Grid container direction='column' spacing={4} zeroMinWidth>
            {!laneId &&
              <Grid item direction='row' className={classes.rowContaier}>
                <Grid direction='column' xs={12} sm={12} md={12} lg={12}>
                  <FieldArray name='lane.stops'
                    render={() => {
                      const tempError = errors as FormikErrors<AwardLaneForm>;
                      const stopsError = typeof tempError?.lane?.stops === 'string' ? <div className={classes.errorMsg}>{tempError.lane.stops}</div> : null;
                      return (
                        <>
                          <Grid item alignItems='flex-end'>
                            {values.lane.stops.map((stop: Stop, index: number) => (
                              <AwardLaneStopDisplay
                                key={index}
                                index={index}
                                errors={errors}
                                setValues={setValues}
                                values={values}
                                activity_type={stop.activity_type} />
                            ))
                            }
                          </Grid>
                          {stopsError}
                        </>
                      );
                    }} />
                </Grid>
              </Grid>}
            <Grid item direction='row' className={classes.rowContaier}>
              <Grid direction='column' xs={12} sm={12} md={12} lg={12}>
                <SelectField
                  id='equipment'
                  label='Service Line'
                  selectedValues={values.equipment}
                  options={equipment}/>
              </Grid>
            </Grid>
            <Grid item direction='row' className={classes.rowContaier}>
              <Grid direction='column' xs={12} sm={12} md={6} lg={6}>
                <InputField
                  id='estimated_miles'
                  label='Miles'
                  inputProps={{ step: 'any' }}
                  type='number'/>
              </Grid>
              <Grid direction='column' xs={12} sm={12} md={6} lg={6}>
                <InputField
                  id='estimated_volume'
                  label='Volume'
                  inputProps={{ step: 'any' }}
                  type='number'/>
              </Grid>
            </Grid>
            <Grid item direction='row' className={classes.rowContaier}>
              <Grid direction='column' xs={12} sm={12} md={6} lg={6}>
                <RadioButtonField
                  id='rate_type'
                  label='Rate type'
                  size='small'
                  onChange={(value: string) => {
                    const previousRateType = values.rate_type;
                    if (previousRateType) {
                      setFieldValue(previousRateType, '');
                    }
                    setFieldValue('rate_type', value);
                  }}
                  defaultValue={values.rate_type}
                  className={classes.radioButtonLabel}
                  options={[
                    { value: 'line_haul', label: 'Line haul' },
                    { value: 'rpm', label: 'RPM' },
                    { value: 'flat_rate', label: 'Flat rate' },
                  ]}/>
              </Grid>
              <Grid direction='column' xs={12} sm={12} md={6} lg={6}>
                {laneId ?
                  <>
                    { values?.rate_type === 'line_haul' && <InputField type='number' id='line_haul' inputProps={{ step: 'any' }} label='Line haul' />}
                    { values?.rate_type === 'rpm' && <InputField type='number' id='rpm' label='RPM' inputProps={{ step: 'any' }} />}
                    { values?.rate_type === 'flat_rate' && <InputField type='number' id='flat_rate' inputProps={{ step: 'any' }} label='Flat rate' />}
                  </> :
                  <>
                    {values.rate_type && (
                      <InputField
                        id={values.rate_type}
                        label={AWARD_RATE_TYPE_LABEL[values.rate_type.toUpperCase()]}
                        type='number'/>
                    )}
                  </>}
              </Grid>
            </Grid>
            <Grid item direction='row' className={classes.rowContaier}>
              <Grid direction='column' xs={12} sm={12} md={6} lg={6}>
                <InputField
                  id='min_rate'
                  label='Min rate'
                  inputProps={{ step: 'any' }}
                  type='number'/>
              </Grid>
              <Grid direction='column' xs={12} sm={12} md={6} lg={6}>
                <InputField
                  id='max_rate'
                  label='Max rate'
                  inputProps={{ step: 'any' }}
                  type='number'/>
              </Grid>
            </Grid>
          </Grid>
        )}
      </FormWrapper>
    </SadStates>
  );
};