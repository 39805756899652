import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  rowContainer: {
    alignItems: 'center',
    padding: '1rem',
    gap: '2rem',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  columnContainer: {
    '&:not(:last-child)': {
      marginBottom: '0.5rem',
    },
  },
}));
