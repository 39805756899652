import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { scrollStyle } from 'src/themeDefault';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  tableContainer: {
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  rowContainer: {
    flexDirection: 'column',
  },
  contentContainer: {
    height: '40rem',
    flexWrap: 'nowrap',
    padding: '0.5rem 0'
  },
  message: {
    padding: '0.5rem 1.5rem'
  },
  rulesContainer: {
    maxHeight: '35rem',
  },
  leftPanel: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '40rem',
    overflowY: 'auto',
    ...scrollStyle as CSSProperties,
  },
  rightPanel: {
    marginRight: 0,
  },
  button: {
    borderBottom: '3px solid transparent',
    borderRadius: 0,
    color: theme.palette.text.primary,
    margin: '0.1rem 1.25rem',
    minWidth: 'auto',
    padding: '0.25rem 0.5rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.primary700,
    },
    '&.selected': {
      borderBottom: `3px solid ${theme.palette.primary.primary700}`,
      color: theme.palette.primary.primary700,
    },
  },
}));
