import React from 'react';

import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { FormWrapper, InputField, SelectField } from 'components';
import { REGIONS } from 'enums';
import { useUpdateFuelSheetMutation } from 'services';
import { FormFuelSheet, FuelSheet } from 'types';
import { getFuelSheetValidationSchema } from 'validations';

type FuelSheetUpdateFormProps = {
  fuelSheet: FuelSheet,
  onCancel: () => void,
  onUpdateSuccess: () => void
};

export const FuelSheetUpdateForm = ({ fuelSheet, onCancel, onUpdateSuccess }: FuelSheetUpdateFormProps) => {
  const queryClient = useQueryClient();

  const { mutate: updateFuelSheet, isLoading: isFuelSheetUpdated } = useUpdateFuelSheetMutation(queryClient, {
    onSuccess: () => {
      onUpdateSuccess();
      onCancel();
      toast.success('Fuel Sheet successfully updated!');
    },
    onError: () => {
      onCancel();
      toast.error('Something went wrong. Please check your input.');
    }
  });

  const initialValues: FormFuelSheet = {
    id: fuelSheet.id,
    region: fuelSheet?.region || '',
    fuel_price_delay: fuelSheet?.fuel_price_delay || 0,
  };

  const onSubmit = (values: FormFuelSheet) => {
    const payload = {
      region: values.region,
      fuel_price_delay: values.fuel_price_delay,
    };
    updateFuelSheet({ id: fuelSheet.id, ...payload });
  };

  return (
    <FormWrapper
      initialValues={initialValues}
      validationSchema={getFuelSheetValidationSchema(fuelSheet.id)}
      validateOnChange
      isActionInProgress={isFuelSheetUpdated}
      primaryButtonLabel={'Save'}
      onCancel={onCancel}
      onSubmit={onSubmit}>
      <SelectField
        id='region'
        selectedValues={initialValues.region}
        label='Region'
        options={REGIONS} />
      <InputField id='fuel_price_delay' label='Fuel Price Delay' type='number' />
    </FormWrapper>
  );
};