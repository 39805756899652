import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { Box } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { Field } from 'formik';

import { FormWrapper, SelectField, UploadFileField } from 'components';
import { FAILED_TO_UPLOAD_FILE_MESSAGE, FILE_CATEGORIES, FILE_CATEGORY, PAGE_TYPE, SUCCESSFULLY_UPLOADED_FILE_MESSAGE, UPLOAD_CSV_XLSX_FILE_TITLE } from 'enums';
import { getUploadAwardMetaDataInitialValues } from 'helpers';
import { AwardMetaData } from 'pages';
import { useGetCustomersQuery, useImportFileMutation } from 'services';
import { Customer, QueryResult, UploadFileWithMetaData } from 'types';
import { uploadFileWithMetaDataValidation } from 'validations';

import { useCommonStyles } from 'styles';
import { useStyle } from './UploadFileModal.css';

type UploadFileModalProps = {
  category?: FILE_CATEGORY,
  onCancel: () => void,
  onUploadSuccess?: () => void
}

export const UploadFileModal = ({ category, onCancel, onUploadSuccess }: UploadFileModalProps) => {
  const classes = useStyle();
  const commonClasses = useCommonStyles();
  const queryClient = useQueryClient();

  const initialValues: UploadFileWithMetaData = {
    file: null as File,
    category: category,
    ...getUploadAwardMetaDataInitialValues(),
  };

  const { isLoading: isLoadingCustomer, data: customerData }: QueryResult<AxiosResponse<Customer[]>> = useGetCustomersQuery();
  const customers = customerData?.data?.map((c: Customer) => {
    return { id: c.id, label: c.customer_name };
  }) || [];

  const { mutate: importFile, isLoading: isImportingFile } = useImportFileMutation({
    onSuccess: () => {
      onUploadSuccess && onUploadSuccess();
      onCancel();
      toast.success(SUCCESSFULLY_UPLOADED_FILE_MESSAGE);
    },
    onError: () => {
      onCancel();
      toast.error(FAILED_TO_UPLOAD_FILE_MESSAGE);
    }
  }, queryClient);

  const fillWithMetaData = (formData: FormData, values: UploadFileWithMetaData) => {
    switch (values.category) {
      case FILE_CATEGORY.AWARD:
        formData.set('name', values.name);
        formData.append('customer', values.customer);
        formData.append('region', values.region);
        formData.append('effective_date', format(new Date(values.effective_date), 'yyyy-MM-dd'));
        formData.append('expiration_date', format(new Date(values.expiration_date), 'yyyy-MM-dd'));
    }
  };

  const handleFileUpload = (values: UploadFileWithMetaData) => {
    const formData = new FormData();
    formData.append('file', values.file);
    formData.append('name', values.file.name);
    formData.append('category', String(values.category));
    formData.append('type', values.file.type);

    fillWithMetaData(formData, values);

    importFile(formData);
  };

  return (
    <FormWrapper<UploadFileWithMetaData>
      initialValues={initialValues}
      validationSchema={uploadFileWithMetaDataValidation}
      validateOnMount
      isActionInProgress={isImportingFile}
      onCancel={onCancel}
      onSubmit={handleFileUpload}>
      {({ values, errors, setFieldValue, setValues }) => (
        <Box>
          <UploadFileField
            id='file'
            accept='.csv, .xlsx'
            className={classes.inputArea}
            title={UPLOAD_CSV_XLSX_FILE_TITLE} />
          {!category &&
            <Box className={classes.formControl}>
              <Field name='category'>
                {() => (
                  <>
                    <SelectField
                      className={commonClasses.selectHeight}
                      id='category'
                      label='File Category'
                      options={FILE_CATEGORIES.sort((a, b) => a.label.localeCompare(b.label))}
                      selectedValues={values.category}
                      onChange={(value: string | number) => {
                        const newValues = {
                          'file': values['file'],
                          'category': value as FILE_CATEGORY,
                          ...getUploadAwardMetaDataInitialValues()
                        };
                        setValues(newValues);
                      }}
                      placeholder='Choose Category'
                      showChips={false} />
                    {values.category === FILE_CATEGORY.AWARD &&
                      <AwardMetaData
                        context={PAGE_TYPE.FILE_UPLOAD}
                        customers={customers}
                        errors={errors}
                        isLoadingCustomer={isLoadingCustomer}
                        values={values}
                        setFieldValue={setFieldValue} />}
                  </>
                )}
              </Field>
            </Box>
          }
        </Box>
      )}
    </FormWrapper>
  );
};