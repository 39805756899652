import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormControl, Grid } from '@mui/material';
import clsx from 'clsx';

import { FormWrapper, InputField, ModalComponent, SelectField, UploadFileField } from 'components';
import { FAILED_TO_UPLOAD_FILE_MESSAGE, FILE_CATEGORY, REGIONS, SUCCESSFULLY_UPLOADED_FILE_MESSAGE, UPLOAD_CSV_FILE_TITLE } from 'enums';
import { useImportFileMutation } from 'services';
import { Customer, FuelSheetImport, Params } from 'types';
import { getFuelSheetValidationSchema } from 'validations';

import { useCommonStyles } from 'styles';
import { useStyle } from './ImportFuelSheetModal.css';

type ImportFuelSheetModalContentProps = {
  customer: Customer,
  onUploadSuccess: () => void,
  onCancel : () => void
};

const ImportFuelSheetModalContent = ({ onCancel, customer, onUploadSuccess } : ImportFuelSheetModalContentProps) => {
  const classes = useStyle();
  const commonClasses = useCommonStyles();
  const { id } = useParams<Params>();

  const initialValues: FuelSheetImport = {
    file: null,
    region: '',
    fuel_price_delay: customer?.default_fuel_price_delay || 0,
  };

  const { mutate: importFile, isLoading } = useImportFileMutation({
    onSuccess: () => {
      toast.success(SUCCESSFULLY_UPLOADED_FILE_MESSAGE);
      onUploadSuccess();
      onCancel();
    },
    onError: () => {
      onCancel();
      toast.error(FAILED_TO_UPLOAD_FILE_MESSAGE);
    }
  });

  const handleImportFuelSheet = (values: FuelSheetImport) => {
    const formData = new FormData();
    formData.append('file', values.file);
    formData.append('name', values.file.name);
    formData.append('type', values.file.type);
    formData.append('category', String(FILE_CATEGORY.FUEL_SHEET));
    formData.append('region', values.region);
    formData.append('customer', id);
    formData.append('fuel_price_delay', String(values.fuel_price_delay));
    importFile(formData);
  };

  return (
    <FormWrapper<FuelSheetImport>
      initialValues={initialValues}
      validationSchema={getFuelSheetValidationSchema}
      validateOnMount
      isActionInProgress={isLoading}
      onCancel={onCancel}
      onSubmit={handleImportFuelSheet}>
      {({ errors }) => (
        <Grid>
          <Grid className={classes.content}>
            <UploadFileField
              id='file'
              accept='.csv'
              className={classes.uploadInput}
              title={UPLOAD_CSV_FILE_TITLE} />
          </Grid>
          <Grid>
            <FormControl error={!!errors['region']} className={clsx(classes.formControl, classes.content)}>
              <SelectField
                id='region'
                className={commonClasses.selectHeight}
                label='Region'
                placeholder='Choose region'
                options={REGIONS} />
            </FormControl>
          </Grid>
          <Grid>
            <InputField
              id='fuel_price_delay'
              label='Fuel Price Delay'
              type='number' />
          </Grid>
        </Grid>
      )}
    </FormWrapper>
  );
};

type ImportFuelSheetModalProps = {
  customer: Customer,
  onUploadSuccess: () => void,
  isModalOpen: boolean,
  onCancel: () => void
};

export const ImportFuelSheetModal = ({ isModalOpen, onCancel, customer, onUploadSuccess } : ImportFuelSheetModalProps) => {
  return (
    <ModalComponent
      modalWidth='xs'
      message='Import fuel sheet'
      isOpen={isModalOpen}
      onCancel={onCancel}>
      <ImportFuelSheetModalContent customer={customer} onCancel={onCancel} onUploadSuccess={onUploadSuccess}/>
    </ModalComponent>
  );
};
