import React from 'react';
import { Divider, Grid } from '@mui/material';
import clsx from 'clsx';

import { ModalComponent, NavigationButton } from 'components';
import { useRuleExecutionLog } from 'contexts';
import { MODAL_CONTENT_TYPE } from 'enums';
import { RuleExecutionLogModalContent } from 'pages';

import { useStyles } from './RuleExecutionLogModal.css';

type RuleExecutionLogModalProps = {
  isOpen: boolean;
  onCancel: () => void;
};

export const RuleExecutionLogModal = ({ isOpen, onCancel }: RuleExecutionLogModalProps) => {
  const classes = useStyles();

  const { modalContent, setModalContent, selectedRule } = useRuleExecutionLog();

  const renderModalMessage = () => {
    if (modalContent === MODAL_CONTENT_TYPE.TABLE) {
      return 'Rule execution logs';
    }

    return (
      <Grid className={classes.title}>
        <NavigationButton
          size='small'
          content='Back'
          className={classes.navigationButton}
          handleClick={() => setModalContent(MODAL_CONTENT_TYPE.TABLE)}/>
        <Divider orientation='vertical' flexItem className={classes.divider} />
        <span>{`Show Rule \u2022 ${selectedRule?.name ?? ''}`}</span>
      </Grid>
    );
  };

  return (
    <ModalComponent
      message={renderModalMessage()}
      isOpen={isOpen}
      modalWidth='md'
      className={clsx({ [classes.container]: modalContent === MODAL_CONTENT_TYPE.TABLE })}
      onCancel={onCancel}
      disableEnforceFocus={true}
      showCloseIcon={true}>
      <RuleExecutionLogModalContent />
    </ModalComponent>
  );
};
