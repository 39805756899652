import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { FILTER_PAGE_SOURCE, PAGE_TYPE } from 'enums';
import { AwardUpsertForm, UpsertPage } from 'pages';
import { Params } from 'types';

type AwardUpsertPageProps = {
  type: PAGE_TYPE,
};

export const AwardUpsertPage = ({ type }: AwardUpsertPageProps) => {
  const history = useHistory();
  const { id } = useParams<Params>();
  const urlTo = '/awards';

  const handleCancel = () => {
    history.push(urlTo);
  };

  return (
    <UpsertPage id={id} type={type} name={FILTER_PAGE_SOURCE.AWARD} urlTo={urlTo}>
      <AwardUpsertForm id={id} onCancel={handleCancel} />
    </UpsertPage>
  );
};
