import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { Layout, NavigationButton } from 'components';
import { PAGE_CONFIG } from 'configs';
import { FILTER_PAGE_SOURCE, PAGE_TYPE } from 'enums';

import { useStyles } from './UpsertPage.css';

type UpsertPageProps = {
  id: string,
  name: FILTER_PAGE_SOURCE,
  type: PAGE_TYPE,
  urlTo: string,
  children?: React.ReactNode;
};

export const UpsertPage = ({ id, name, type, urlTo, children }: UpsertPageProps) => {
  const classes = useStyles();

  const getPageTitle = () => {
    const title = PAGE_CONFIG[name][type];
    return id ? `${title} \u2022 ${id}` : title;
  };

  return (
    <Layout title={<NavigationButton size='small' content='Back' urlTo={urlTo} />}>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Typography variant='h2' className={classes.title}>
            {getPageTitle()}
          </Typography>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};
