import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyle = makeStyles((theme: ThemeDefault) => ({
  formControl: {
    '&.MuiFormControl-root': {
      alignItems: 'center',
      display: 'flex',
      width: 'inherit',
      border: `1px dashed ${theme.palette.secondary.secondary500}`,
      borderRadius: '0.5rem',
    },
    '& .MuiButtonBase-root': {
      borderRadius: '0.5rem',
      width: 'fit-content',
    },
  },
  uploadTitle: {
    ...theme.typography.bodyRegularS,
    marginBottom: '0.625rem',
  },
  uploadedFileChipContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    maxWidth: '90%',
    '& .MuiChip-root': {
      backgroundColor: 'transparent',
      '& svg': {
        width: '0.625rem',
        height: '0.625rem',
        '& path': {
          fill: theme.palette.neutral.neutral400,
        },
      },
    },
  },
  uploadedFileName: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.primary.primary700,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  errorMsg: {
    ...theme.typography.bodyRegularXS,
    color: theme.palette.error.main
  },
  hiddenInput: {
    display: 'none',
  },
}));
