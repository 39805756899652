import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import {
  Button,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { ActionButton, FormWrapper, InputField } from 'components';
import { useAuth } from 'contexts';
import { ErrorIndicatorIcon, googleImg, togglePasswordImg } from 'resources';
import { getValidationSchema } from './validation';

import { useStyles } from './LoginPage.css';

interface LoginFormValues {
  email: string;
  password: string;
}

export const LoginPage = () => {
  const classes = useStyles();

  const { googleUnAuthMsg, login, loginGoogle } = useAuth();
  const history = useHistory();

  const [ isError, setIsError ] = useState(false);
  const [ showPassword, setShowPassword ] = useState(false);

  const loginFormRef = useRef<FormikProps<LoginFormValues>>();

  const initialValues = useMemo(() => ({
    email: '',
    password: ''
  }), []);

  const validationSchema = useMemo(() => getValidationSchema(), []);

  const handleLogin = async (email: string, pass: string) => {
    setIsError(false);
    if (email && pass) {
      try {
        await login(email, pass);
        history.replace('/');
      } catch (error) {
        setIsError(true);
      }
    }
  };

  const handleLoginWithGoogle = async () => {
    setIsError(false);
    try {
      await loginGoogle();
    } catch (error) {
      setIsError(true);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (googleUnAuthMsg && loginFormRef) {
      loginFormRef.current.resetForm();
    }
  }, [googleUnAuthMsg]);

  return (
    <Grid className={classes.background}>
      <FormWrapper
        initialValues={initialValues}
        enableReinitialize = {false}
        validationSchema={validationSchema}
        innerRef={loginFormRef}>
        {({ values }) => (
          <Grid className={classes.contentWrapper}>
            <Grid className={clsx(classes.gridContainer, classes.gridColumn)}>
              <Typography variant='h1'>Log in</Typography>
              {isError && (
                <Alert
                  severity='error'
                  icon={<ErrorIndicatorIcon />}
                  className={classes.errorPopup}
                  onClose={() => setIsError(false)}>
                  <Typography className={classes.errorPopupText}>
                    Sorry we don’t have account with entered email or password in our records.
                  </Typography>
                </Alert>
              )}
              <Grid className={classes.inputContainer}>
                <InputField
                  id='email'
                  label='E-mail*'
                  placeholder='Enter your email address'
                  className={classes.input}/>
              </Grid>
              <Grid className={classes.inputContainer}>
                <InputField
                  id='password'
                  label='Password*'
                  placeholder='Enter your password'
                  className={classes.input}
                  type={showPassword ? 'text' : 'password'}
                  endIcon={
                    <img className={classes.togglePasswordImg} src={togglePasswordImg} alt='toggle-password-img' onClick={() => handleTogglePassword()} />
                  }/>
              </Grid>
              <ActionButton
                variant='primary'
                type='submit'
                text='Log In'
                className={classes.loginButton}
                handleClick={() => handleLogin(values.email, values.password)}/>
              <Grid className={classes.separator}>
                <Divider className={classes.dividerStyles} />
                <Typography className={classes.separatorText}>OR</Typography>
                <Divider className={classes.dividerStyles} />
              </Grid>
              <Button onClick={handleLoginWithGoogle} className={classes.googleButton}>
                <Grid className={classes.gridContainer}>
                  <img src={googleImg} alt='google icon' />
                  <Typography className={classes.signUpWithGoogleText}>Sign in with Google</Typography>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        )}
      </FormWrapper>
      <footer className={classes.footer}>
        <Typography className={classes.footerText}>
          NFI Industries @ {new Date().getFullYear()}. All rights reserved.
        </Typography>
      </footer>
    </Grid>
  );
};
