import React, { useRef } from 'react';
import { Chip, FormControl, FormHelperText, Grid, Typography, } from '@material-ui/core';
import { clsx } from 'clsx';

import { ActionButton } from 'components';
import { UploadIcon, XMark } from 'resources';

import { useStyle } from './UploadFileField.css';
import { Field, FieldProps } from 'formik';

type UploadFileFieldProps = {
  accept: string;
  className?: string;
  id: string;
  title: string;
}

export const UploadFileField = ({ accept, className, id, title}: UploadFileFieldProps) => {
  const classes = useStyle();
  const inputRef = useRef<HTMLInputElement>();

  return (
    <Field name={id}>
      {({ form }: FieldProps) => (
        <>
          <FormControl error={!!form.errors[id]} className={clsx(classes.formControl, className)}>
            <Typography className={classes.uploadTitle}>{title}</Typography>
            <input
              accept={accept}
              className={classes.hiddenInput}
              id={id}
              onChange={(e) => {
                form.setFieldValue(id, e.target.files[0]);
              }}
              ref={inputRef}
              type='file' />
            <ActionButton
              text='Upload'
              variant='default'
              startIcon={<UploadIcon />}
              handleClick={() => inputRef.current.click()} />
            {form.values[id] &&
              <Grid className={classes.uploadedFileChipContainer}>
                <Typography className={classes.uploadedFileName}>{(form.values[id] as File).name}</Typography>
                <Chip deleteIcon={<XMark />} onDelete={() => form.setFieldValue(id, null)} />
              </Grid>}
          </FormControl>
          <FormHelperText className={classes.errorMsg}>{(form.errors as Record<string, string>)[id] || ' '}</FormHelperText>
        </>
      )}
    </Field>
  );
};