import {
  quoteOpenIcon,
  quoteWonIcon,
  quoteLostIcon,
  quoteRespondedIcon,
  quoteRejectedIcon,
  quoteLabelExpiredIcon,
  quoteLabelOpenIcon,
  quoteLabelWonIcon,
  quoteLabelLostIcon,
  quoteLabelRespondedIcon,
  quoteLabelRejectedIcon,
} from 'resources';

export const QUOTE_REQUEST_SOURCES = [
  { name: 'Account manager', value: 0, isActive: false },
  { name: 'New Lane', value: 1, isActive: true },
  { name: 'Project Freight', value: 2, isActive: true },
  { name: 'Expedited shipment', value: 3, isActive: true },
  { name: 'Forecasted volume increase', value: 4, isActive: true },
  { name: 'Current carriers unable to commit', value: 5, isActive: true },
  { name: 'Specialized commodity', value: 6, isActive: false },
  { name: 'Searching for better rate', value: 7, isActive: true },
  { name: 'Possible carrier replacement', value: 8, isActive: true }
];

export const GREENSCREENS_CONFIDENCE_LEVEL = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
  EXTREMELY_LOW: 'EXTREMELY_LOW'
};

export const EQUIPMENT_LABEL = {
  DRY_VAN: 'Dry Van',
  REEFER: 'Reefer',
  FLATBED: 'Flatbed',
  STEP_DECK: 'Step Deck',
  INTERMODAL: 'Intermodal',
  POWER_ONLY: 'Power Only'
};

export enum QUOTE_STATUS {
  OPEN,
  RESPONDED,
  WON,
  LOST,
  REJECTED,
  EXPIRED
}

export const QUOTE_STATUS_LABELS: Record<string, string> = {
  [QUOTE_STATUS.OPEN]: 'Open',
  [QUOTE_STATUS.RESPONDED]: 'Responded',
  [QUOTE_STATUS.WON]: 'Won',
  [QUOTE_STATUS.LOST]: 'Lost',
  [QUOTE_STATUS.REJECTED]: 'Rejected',
  [QUOTE_STATUS.EXPIRED]: 'Expired'
};

export const LOST_STATUS = {
  CUSTOMERREQUIREMENTSCHANGE: 0,
  NFIERROR: 1,
  PRICESHOPPING: 2,
  PRIMARY: 3,
  QUOTECANCELLED: 4,
  RATETOOHIGH: 5,
  TIMING: 6,
};

export const LOST_STATUS_LABELS = {
  [LOST_STATUS.CUSTOMERREQUIREMENTSCHANGE]: 'Shipper requirements changed',
  [LOST_STATUS.NFIERROR]: 'NFI Error',
  [LOST_STATUS.PRICESHOPPING]: 'Price Shopping',
  [LOST_STATUS.PRIMARY]: 'Primary Carrier Re-Acceptance',
  [LOST_STATUS.QUOTECANCELLED]: 'Quote cancelled',
  [LOST_STATUS.RATETOOHIGH]: 'Rate too high',
  [LOST_STATUS.TIMING]: 'Timing/Flexibility',
};

export const REJECTION_STATUS = {
  LEADTIME: 0,
  EQUIPMENT: 1,
  LANE: 2,
  VOLUMEINCORIDOR: 3,
  AVAILABLECAPACITY: 4
};

export const REJECTION_STATUS_LABELS = {
  [REJECTION_STATUS.LEADTIME]: 'Lead Time',
  [REJECTION_STATUS.EQUIPMENT]: 'Equipment/Shipment Type',
  [REJECTION_STATUS.LANE]: 'Lane',
  [REJECTION_STATUS.VOLUMEINCORIDOR]: 'Current Volume in Corridor',
  [REJECTION_STATUS.AVAILABLECAPACITY]: 'Available Capacity'
};

export const QUOTE_STATUS_ICONS = {
  [QUOTE_STATUS.OPEN]: quoteOpenIcon,
  [QUOTE_STATUS.WON]: quoteWonIcon,
  [QUOTE_STATUS.LOST]: quoteLostIcon,
  [QUOTE_STATUS.RESPONDED]: quoteRespondedIcon,
  [QUOTE_STATUS.REJECTED]: quoteRejectedIcon,
  [QUOTE_STATUS.EXPIRED]: quoteLostIcon
};

export const QUOTE_LABEL_ICONS = {
  [QUOTE_STATUS.OPEN]: quoteLabelOpenIcon,
  [QUOTE_STATUS.WON]: quoteLabelWonIcon,
  [QUOTE_STATUS.LOST]: quoteLabelLostIcon,
  [QUOTE_STATUS.RESPONDED]: quoteLabelRespondedIcon,
  [QUOTE_STATUS.REJECTED]: quoteLabelRejectedIcon,
  [QUOTE_STATUS.EXPIRED]: quoteLabelExpiredIcon
};

export enum QUOTE_SERVICE_TYPE {
  AIR,
  IM,
  LTL,
  OCEAN,
  PARCEL,
  RAIL,
  TL
}

export const QUOTE_SERVICE_TYPE_LABELS = {
  [QUOTE_SERVICE_TYPE.AIR]: 'Air',
  [QUOTE_SERVICE_TYPE.IM]: 'IM',
  [QUOTE_SERVICE_TYPE.LTL]: 'LTL',
  [QUOTE_SERVICE_TYPE.OCEAN]: 'Ocean',
  [QUOTE_SERVICE_TYPE.PARCEL]: 'Parcel',
  [QUOTE_SERVICE_TYPE.RAIL]: 'Rail',
  [QUOTE_SERVICE_TYPE.TL]: 'TL'
};

export enum QUOTE_SOURCE {
  QQT_UI,
  BY,
  TRANSPLACE,
  QQT_API,
  GMAIL,
  TMS_3G,
  EMERGE_TMS,
  CSV_IMPORT,
  TMC,
  LOADSMART,
  E2OPEN
}

export enum STOP_ACTIVITY_TYPES {
  PICKUP = 1,
  DELIVERY = 2
}

export const QUOTE_SOURCE_LABEL: Record<string, string> = {
  [QUOTE_SOURCE.QQT_UI]: 'NFQT',
  [QUOTE_SOURCE.BY]: 'Blue Yonder',
  [QUOTE_SOURCE.TRANSPLACE]: 'Transplace',
  [QUOTE_SOURCE.QQT_API]: 'QQT API',
  [QUOTE_SOURCE.GMAIL]: 'Mail',
  [QUOTE_SOURCE.TMS_3G]: '3G TMS',
  [QUOTE_SOURCE.EMERGE_TMS]: 'Emerge TMS',
  [QUOTE_SOURCE.TMC]: 'TMC',
  [QUOTE_SOURCE.LOADSMART]: 'Loadsmart',
  [QUOTE_SOURCE.E2OPEN]: 'e2open'
};

export const QUOTE_HISTORY_FILTER_OPTIONS = [
  { id: '-proximity', label: 'Proximity'},
  { id: '-created_at', label: 'Created time'}
];
export enum RULE_EXECUTION_FILTER {
  EXECUTED = '-executed',
  ALL = '-all',
}
export const QUOTE_RULE_EXECUTION_FILTER_OPTIONS = [
  { id: RULE_EXECUTION_FILTER.EXECUTED, label: 'Executed'},
  { id: RULE_EXECUTION_FILTER.ALL, label: 'All rules'}
];
export enum QUOTE_RULE_ENGINE_RESPONSE_STATUS {
  RESPOND = 'respond',
  NO_REPLY = 'no-reply',
  REJECT = 'reject',
}