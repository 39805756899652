import React, { useState } from 'react';
import { Divider, Grid, Button } from '@mui/material';
import clsx from 'clsx';

import { CreateUpdateRuleModal } from 'components';
import { useRuleExecutionLog } from 'contexts';
import { MODAL_CONTENT_TYPE } from 'enums';
import { RuleExecutionLog } from 'pages';
import { QuoteExecutionLogData, RulesetExecutionLog } from 'types';

import { useStyles } from './RuleExecutionLogModalContent.css';

export const RuleExecutionLogModalContent = () => {
  const classes = useStyles();

  const { modalContent, setSelectedRule, selectedRule, orderedRuleset, ruleEditorConfiguration, rulesetExecutionLogs } = useRuleExecutionLog();
  const [ selectedButton, setSelectedButton ] = useState<number>(0);

  return (
    <>
      {modalContent === MODAL_CONTENT_TYPE.TABLE && (
        <Grid spacing={1} className={classes.tableContainer}>
          <Grid item >
            <Divider />
          </Grid>
          <Grid container spacing={2} className={classes.contentContainer}>
            <Grid item className={classes.leftPanel}>
              {rulesetExecutionLogs?.map((_: RulesetExecutionLog<QuoteExecutionLogData>, index: number) => (
                <Button
                  key={index}
                  variant='text'
                  className={clsx(classes.button, {selected: selectedButton === index})}
                  onClick={() => setSelectedButton(index)}>
                  Execution {rulesetExecutionLogs.length - index}
                </Button>
              ))}
            </Grid>
            <Divider orientation='vertical' flexItem />
            <Grid item xs className={classes.rightPanel}>
              <RuleExecutionLog ruleExecutionLog={rulesetExecutionLogs[selectedButton]} className={classes.rulesContainer}/>
            </Grid>
          </Grid>
        </Grid>
      )}
      {modalContent === MODAL_CONTENT_TYPE.RULE && (
        <Grid container spacing={1} className={classes.rowContainer}>
          <CreateUpdateRuleModal rules={orderedRuleset} rule={selectedRule} ruleEditorConfiguration={ruleEditorConfiguration} onCancel={() => setSelectedRule(null)} />
        </Grid>
      )}
    </>
  );
};
