import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  container: {
    '& .MuiDialogTitle-root': {
      padding: '1.875rem 1.875rem 0 1.875rem',
    },
    '& .MuiDialogContent-root': {
      padding: '1rem 1.125rem',
    },
  },
  divider: {
    width: '0.1rem'
  },
  navigationButton: {
    background: theme.palette.neutral.neutral0,
    justifyContent: 'center !important',
    '&:hover': {
      backgroundColor: `${theme.palette.neutral.neutral0} !important`
    },
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    gap: '0.5rem',
  },
}));
