import React from 'react';
import { useTheme } from '@material-ui/core';

import {ReactComponent as AwardsIcon} from 'resources/img/sidebar/awards.svg';
import {ReactComponent as FilesIcon} from 'resources/img/sidebar/files.svg';
import {ReactComponent as IntegrationLogsIcon} from 'resources/img/sidebar/integration-logs.svg';
import {ReactComponent as MaxBuyRulesIcon} from 'resources/img/sidebar/max-buy-rules.svg';
import {ReactComponent as QuoteDashboardIcon} from 'resources/img/sidebar/quote-dashboard.svg';
import {ReactComponent as RulesIcon} from 'resources/img/sidebar/rules.svg';
import {ReactComponent as ShippersIcon} from 'resources/img/sidebar/shippers.svg';
import {ReactComponent as WorkingCopiesIcon} from 'resources/img/sidebar/working-copies.svg';

export const Icons = ({iconName, strokeColor = null, fillColor = null}) => {
  const theme = useTheme();
  const neutralColor = theme.palette.secondary.secondary700;

  let icon = null;
  switch (iconName) {
    case 'awardsIcon':
      icon = <AwardsIcon stroke={strokeColor ? strokeColor : neutralColor}/>;
      break;
    case 'filesIcon':
      icon = <FilesIcon fill={fillColor ? fillColor : neutralColor}/>;
      break;
    case 'integrationLogsIcon':
      icon = <IntegrationLogsIcon fill={fillColor ? fillColor : neutralColor}/>;
      break;
    case 'maxBuyRulesIcon':
      icon = <MaxBuyRulesIcon fill={fillColor ? fillColor : neutralColor}/>;
      break;
    case 'quoteDashboardIcon':
      icon = <QuoteDashboardIcon stroke={strokeColor ? strokeColor : neutralColor}/>;
      break;
    case 'rulesIcon':
      icon = <RulesIcon stroke={strokeColor ? strokeColor : neutralColor}/>;
      break;
    case 'shippersIcon':
      icon = <ShippersIcon fill={fillColor ? fillColor : neutralColor}/>;
      break;
    case 'workingCopiesIcon':
      icon = <WorkingCopiesIcon fill={fillColor ? fillColor : neutralColor}/>;
      break;
  }
  return icon;
};