import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';

import { ActionButton } from 'components';

import { useStyles } from './FormWrapper.css';

type FormWrapperProps<T> = {
  children: ((context: FormikProps<T>) => React.ReactNode[] | React.ReactNode ) | React.ReactNode,
  enableReinitialize?: boolean,
  initialValues: unknown,
  innerRef?: React.MutableRefObject<FormikProps<T>>,
  isActionInProgress?: boolean,
  noValidate?: boolean,
  onCancel?: () => void,
  onSubmit?: (payload: unknown) => void,
  primaryButtonLabel?: string,
  validateOnChange?: boolean,
  validateOnMount?: boolean,
  validationSchema?: unknown,
}

export const FormWrapper = <T, >({
  children,
  enableReinitialize = true,
  initialValues,
  innerRef,
  isActionInProgress,
  noValidate = false,
  onCancel,
  onSubmit,
  primaryButtonLabel = 'Confirm',
  validateOnChange,
  validateOnMount,
  validationSchema
}: FormWrapperProps<T>) => {
  const classes = useStyles();
  const showActionButtons: boolean = useMemo(() => !!onSubmit, [onSubmit]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={validateOnMount}
      validateOnChange={validateOnChange}
      enableReinitialize={enableReinitialize}
      innerRef={innerRef}
      onSubmit={onSubmit}>
      {(context) => (
        <Form noValidate={noValidate}>
          {typeof children === 'function' ? children(context as FormikProps<T>) : children}
          {showActionButtons &&
          <Grid container justify='flex-end' className={classes.actions}>
            <Grid item>
              <ActionButton handleClick={onCancel} variant='secondary' text='Cancel' />
            </Grid>
            <Grid item>
              <ActionButton
                type='submit'
                variant='primary'
                isLoading={isActionInProgress}
                disabled={!context.isValid || isActionInProgress}
                text={primaryButtonLabel} />
            </Grid>
          </Grid>
          }
        </Form>
      )}
    </Formik>
  );
};