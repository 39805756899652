import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyle = makeStyles((theme: ThemeDefault) => ({
  content: {
    marginBottom: '1rem'
  },
  uploadInput: {
    backgroundColor: theme.palette.primary.primary100,
    padding: '1.5rem',
    '& .MuiButtonBase-root': {
      margin: '1rem auto',
      width: 'fit-content'
    },
  },
  formControl: {
    width: '100%',
  }
}));
