import { FIELD_TYPE, PAGE_TYPE, REGIONS } from 'enums';
import { AwardFieldConfig } from 'types';

export const fieldConfig: Record<PAGE_TYPE, AwardFieldConfig[][]> = {
  [PAGE_TYPE.CREATE]: [
    [{ id: 'name', label: 'Sheet name', type: 'text', placeholder: 'Enter sheet name', component: FIELD_TYPE.INPUT },],
    [{ id: 'customer', label: 'Shipper', placeholder: 'Choose or enter shipper partner', component: FIELD_TYPE.AUTOCOMPLETE },
     { id: 'region', label: 'Fuel Sheet Region', placeholder: 'Choose fuel sheet region', component: FIELD_TYPE.SELECT, options: REGIONS },],
    [{ id: 'effective_date', label: 'Effective Date', component: FIELD_TYPE.DATE },
     { id: 'expiration_date', label: 'Expiration Date', component: FIELD_TYPE.DATE },],
  ],
  [PAGE_TYPE.EDIT]: [
    [{ id: 'name', label: 'Sheet name', type: 'text', placeholder: 'Enter sheet name', component: FIELD_TYPE.INPUT },
     { id: 'region', label: 'Fuel Sheet Region', component: FIELD_TYPE.SELECT, options: REGIONS },],
    [{ id: 'effective_date', label: 'Effective Date', component: FIELD_TYPE.DATE },
     { id: 'expiration_date', label: 'Expiration Date', component: FIELD_TYPE.DATE },],
  ],
  [PAGE_TYPE.FILE_UPLOAD]: [
    [{ id: 'name', label: 'Sheet name', type: 'text', placeholder: 'Enter sheet name', component: FIELD_TYPE.INPUT },],
    [{ id: 'customer', label: 'Shipper', placeholder: 'Choose or enter shipper partner', component: FIELD_TYPE.AUTOCOMPLETE },],
    [{ id: 'region', label: 'Fuel Sheet Region', placeholder: 'Choose fuel sheet region', component: FIELD_TYPE.SELECT, options: REGIONS },],
    [{ id: 'effective_date', label: 'Effective Date', component: FIELD_TYPE.DATE },],
    [{ id: 'expiration_date', label: 'Expiration Date', component: FIELD_TYPE.DATE },]
  ],
};
