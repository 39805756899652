import React, { FC, useEffect, useRef, useState } from 'react';

import { NSelect } from 'components';
import { initLiteral } from 'services/ruleEngine';
import { Expression, ExpressionEditorBaseProps, Operation, Option } from 'types';

import expressionEditorStyles from 'components/RuleEngine/ExpressionEditor.css';

interface OperationEditorProps extends ExpressionEditorBaseProps {
  expression?: Operation;
}

const OperationEditor: FC<OperationEditorProps> = ({ resultType, config, expression, onUpdate, isReadOnly=false }) => {
  const styles = expressionEditorStyles();
  const options: Option[] = config.operations
    .filter(o => o.result_type === resultType)
    .map(o => ({ id: o.name, label: o.display_name }));
  const [ selectedOperation, setSelectedOperation ] = useState<string>(options.find(o => o.id === expression.name)?.id.toString() || options[0]?.id.toString() || '');
  const operationCache = useRef<Map<string, Expression[]>>(new Map());

  useEffect(() => {
    if (!selectedOperation) {
      return;
    }
    const args = operationCache.current.get(selectedOperation) || initArgs();
    onUpdate({ ...expression, name: selectedOperation, args });
  }, [selectedOperation]);

  useEffect(() => {
    operationCache.current.set(expression.name, expression.args);
  }, [expression]);

  const initArgs = () => {
    // TODO: Flexible number of arguments from config
    return [
      initLiteral(resultType, 1),
      initLiteral(resultType, 2),
    ];
  };

  const onOperationChange = (operationName: string) => {
    // XXX <SelectFilter /> component emits change event too eagerly
    if (selectedOperation === operationName) {
      return;
    }
    setSelectedOperation(operationName);
  };

  return (
    <NSelect
      selectedValues={selectedOperation}
      onChange={onOperationChange}
      options={options}
      className={styles['expression-node-wrapper__header-control']}
      placeholder='Choose Operation'
      readOnly={isReadOnly}/>
  );
};

export default OperationEditor;
