import React from 'react';
import { QueryObserverResult, RefetchOptions, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

import { FormWrapper, SelectField } from 'components';
import { useGetEquipmentTypesQuery, useSetEquipmentTypeMutation } from 'services';
import { QuoteDetails } from 'types';
import { getValidationSchema } from './validation';

type SetEquipmentTypeProps = {
  equipmentId: number,
  onCancel: () => void,
  refetchQuote: (options?: RefetchOptions) => Promise<QueryObserverResult<AxiosResponse<QuoteDetails>, unknown>>
};

export const SetEquipmentTypeModal = ({
  equipmentId,
  onCancel,
  refetchQuote
}: SetEquipmentTypeProps) => {
  const queryClient = useQueryClient();

  const { isLoading: isLoadingEquipment, data: equipmentData } = useGetEquipmentTypesQuery();
  const equipment = equipmentData?.data?.map((eq) => {
    return { id: eq.type, label: eq.type };
  }) || [];

  const { mutate: setEquipmentType, isLoading: isSettingEquipmentType } = useSetEquipmentTypeMutation(queryClient, {
    onSuccess: () => {
      onCancel();
      toast.success('Equipment type successfully set');
      refetchQuote();
    }
  });

  const onSubmit = (values: { type: string; }) => {
    setEquipmentType({ id: equipmentId, type: values.type });
  };

  return (
    <FormWrapper<{ type: string; }>
      initialValues={{ type: '' }}
      validationSchema={getValidationSchema}
      validateOnChange
      validateOnMount
      isActionInProgress={isLoadingEquipment || isSettingEquipmentType}
      onCancel={onCancel}
      onSubmit={onSubmit}>
      {({ values }) => (
        <SelectField
          id='type'
          label=''
          placeholder='Choose type'
          selectedValues={values.type}
          options={equipment}/>
      )}
    </FormWrapper>
  );
};
