import React from 'react';
import { TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import clsx from 'clsx';

import { CURRENCY_TYPE_LABEL, DEFAULT_DISPLAY_VALUE } from 'enums';
import { GSPredictionRate } from 'types';

import { useStyles } from './QuoteDetailsPage.css';

type PredictionRateDetailsProps = {
  predictionRate: GSPredictionRate,
  distance: number,
  currency: string
};

export const PredictionRateDetails = ({ predictionRate, distance, currency } : PredictionRateDetailsProps) => {
  const classes = useStyles();

  const {
    prediction_confidence_level_str,
    prediction_confidence_level,
    prediction_fuel_rate,
    prediction_high_buy_rate,
    prediction_low_buy_rate,
    prediction_target_buy_rate,
    network_confidence_level_str,
    network_confidence_level,
    network_fuel_rate,
    network_high_buy_rate,
    network_low_buy_rate,
    network_target_buy_rate,
  } = predictionRate || {};

  const formatPrice = (rate: number) => {
    return rate ? `${CURRENCY_TYPE_LABEL[currency]}${(rate * distance).toFixed(2)}` : DEFAULT_DISPLAY_VALUE;
  };
  const formatConfidenceLevel = (labelStr: string | null, value: number | null) =>
    labelStr && value ? `${labelStr} (${value} %)` : DEFAULT_DISPLAY_VALUE;

  const rows = [
    {
      label: 'Target Buy',
      targetValue: formatPrice(prediction_target_buy_rate),
      networkValue: formatPrice(network_target_buy_rate),
    },
    {
      label: 'Low Buy',
      targetValue: formatPrice(prediction_low_buy_rate),
      networkValue: formatPrice(network_low_buy_rate),
    },
    {
      label: 'High Buy',
      targetValue: formatPrice(prediction_high_buy_rate),
      networkValue: formatPrice(network_high_buy_rate),
    },
    {
      label: 'Confidence Level',
      targetValue: formatConfidenceLevel(prediction_confidence_level_str, prediction_confidence_level),
      networkValue: formatConfidenceLevel(network_confidence_level_str, network_confidence_level),
    },
    {
      label: 'Fuel Cost',
      targetValue: formatPrice(prediction_fuel_rate),
      networkValue: formatPrice(network_fuel_rate),
    },
  ];
  const header = [ <Typography key='header-title' variant='h4' className={classes.cardTitle}>Greenscreens Prediction Rates</Typography>, 'Target', 'Network' ];

  return (
    <TableContainer className={classes.tableContainer}>
      <TableRow className={classes.tableRow}>
        {header.map((headerLabel, headerIndex) => (
          <TableCell key={headerIndex} className={clsx(classes.tableCell, classes.labelText, headerIndex === 0 && classes.rowNameColumn)}>
            {headerLabel}
          </TableCell>
        ))}
      </TableRow>
      {rows.map((row, rowIndex) => (
        <TableRow key={rowIndex} className={classes.tableRow}>
          <TableCell className={clsx(classes.tableCell, classes.labelText, classes.rowNameColumn)}>
            {row.label}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {row.targetValue}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {row.networkValue}
          </TableCell>
        </TableRow>
      ))}
    </TableContainer>
  );
};
