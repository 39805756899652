import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { Field, FieldProps, FormikProps } from 'formik';

import { useStyles } from './InputField.css';

type InputFieldProps = OutlinedInputProps & {
  className?: string;
  endIcon?: string | JSX.Element;
  id: string;
  label?: string;
  onChange?: (v: string) => void;
  startIcon?: string;
};

export const InputField: React.FC<InputFieldProps> = ({
  className,
  endIcon,
  id,
  label,
  onChange,
  startIcon,
  ...outlinedInputProps
}) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, form: FormikProps<unknown>, id: string) => {
    form.setFieldValue(id, event.target.value);
    onChange && onChange(event.target.value);
  };

  const getFieldError = (form: FormikProps<unknown>, id: string) => {
    // `form` is passed to the function because `eval` needs to have access to `form`
    try {
      return eval(`form.errors.${id}`);
    } catch (e) {
      // If there is no error for specific field in `form.errors` neasted properties
      // like `custom_fields.min_temp` will throw an error since `min_temp` cannot be accessed if
      // `custom_fields` is not present in the object
      return;
    }
  };

  return (
    <div>
      {label && <Typography className={classes.title}>{label}</Typography>}
      <Field name={id} className={classes.field}>
        {({ form } : FieldProps) => (
          <FormControl error={!!getFieldError(form, id)} className={classes.formControl}>
            <OutlinedInput
              className={clsx(classes.input, className)}
              id={id}
              onChange={(e) => handleChange(e, form, id)}
              onFocus={(e) => e.target.select()}
              value={eval(`form.values.${id}`) ?? ''}
              startAdornment={startIcon && <InputAdornment position='start' className={classes.startAdornment}>{startIcon}</InputAdornment>}
              endAdornment={endIcon && <InputAdornment position='end' className={classes.endAdornment}>{endIcon}</InputAdornment>}
              {...outlinedInputProps}/>
            <FormHelperText className={classes.errorMsg}> {getFieldError(form, id) || ' '}</FormHelperText>
          </FormControl >
        )}
      </Field>
    </div>
  );
};