import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { AxiosError, AxiosResponse } from 'axios';
import { FormikProps } from 'formik';

import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';

import { AutocompleteFilter, CollapsibleButton, FormWrapper, InputField, PageLoadingState, SadStates, SelectField } from 'components';
import { COST_TYPE, COST_TYPE_LABELS, SHIPPER_FORM_GROUP, SHIPPER_FORM_GROUP_LABELS } from 'enums';
import { formatToUniqueOptionsByProperty } from 'helpers';
import { useCreateCustomerMutation, useGetCustomerById, useGetCustomersQuery, useGetUsersQuery, useUpdateCustomerMutation } from 'services';
import { Customer, FormCustomer, Params, QueryResult, User } from 'types';
import { getCustomerUpsertValidationSchema } from 'validations';

import { useStyles } from './CustomerUpsertForm.css';

type CustomerUpsertFormProps = {
  onCancel: () => void,
};

export const CustomerUpsertForm = ({ onCancel }: CustomerUpsertFormProps) => {
  const queryClient = useQueryClient();
  const formRef = useRef<FormikProps<FormCustomer>>();
  const classes = useStyles();
  const { id } = useParams<Params>();
  const shipperId = Number(id);

  const [ expandedFormGroup, setExpandedFormGroup ] = useState<number | null>(SHIPPER_FORM_GROUP.GENERAL_INFORMATION);

  const { data: customerData, isLoading: isLoadingCustomer } = useGetCustomerById(shipperId);
  const { data: customer } = customerData || {};

  const { data: customersData, isLoading: isLoadingCustomers }: QueryResult<AxiosResponse<Customer[]>> = useGetCustomersQuery();
  const tmsOptions = formatToUniqueOptionsByProperty(customersData?.data, 'tms') || [];

  const { data: userData, isLoading: isLoadingUsers } = useGetUsersQuery();
  const users = userData?.data?.map((c: User) => {
    return { id: c.id, label: c.display_name };
  }) || [];

  const { mutate: createCustomer, isLoading: isCustomerCreated } = useCreateCustomerMutation(queryClient, {
    onSuccess: () => {
      onCancel();
      toast.success('Shipper successfully created!');
    },
    onError: (error) => {
      checkFieldError(error);
    }
  });
  const { mutate: updateCustomer, isLoading: isCustomerUpdated } = useUpdateCustomerMutation(queryClient, {
    onSuccess: () => {
      onCancel();
      toast.success('Shipper successfully updated!');
    },
    onError: (error) => {
      checkFieldError(error);
    }
  });

  const initialValues: FormCustomer = {
    name: customer?.customer_name || '',
    code: customer?.customer_slug || '',
    email: customer?.contact_email || '',
    contactName: customer?.contact_name || '',
    phone: customer?.contact_phone || '',
    profitCenter: customer?.profit_center || '',
    nfiPricingContact: customer?.nfi_pricing_contact || null,
    costType: customer?.cost_type || COST_TYPE.ALLIN,
    defaultFuelPriceDelay: customer?.default_fuel_price_delay || 0,
    autoResponse: customer?.auto_response,
    notes: customer?.notes || '',
    channelId: customer?.channel_id || '',
    tms: customer?.tms || '',
    quoteExpirationPeriod: customer?.quote_expiration_period || null,
    vertical: customer?.vertical || '',
  };

  const checkFieldError = (error: AxiosError<{
    customer_name?: string[],
    customer_slug?: string[]
  }>) => {
    const nameErr = error.response.data.customer_name?.[0];
    const slugErr = error.response.data.customer_slug?.[0];

    if (nameErr) {
      formRef.current.setFieldError('name', nameErr);
    }
    if (slugErr) {
      formRef.current.setFieldError('code', slugErr);
    }
  };

  const onSubmit = (values: FormCustomer) => {
    const payload = {
      customer_name: values.name,
      customer_slug: values.code,
      contact_email: values.email,
      contact_name: values.contactName,
      contact_phone: values.phone,
      profit_center: values.profitCenter,
      nfi_pricing_contact: values.nfiPricingContact,
      cost_type: +values.costType,
      default_fuel_price_delay: values.defaultFuelPriceDelay,
      auto_response: values.autoResponse,
      notes: values.notes,
      channel_id: values.channelId,
      tms: values.tms,
      quote_expiration_period: (values.quoteExpirationPeriod === null || values.quoteExpirationPeriod.toString() === '') ? null : values.quoteExpirationPeriod,
      vertical: values.vertical
    };

    customer ? updateCustomer({ id: customer.id, ...payload }) : createCustomer(payload);
  };

  const isLoadingPage = isLoadingCustomer || isLoadingCustomers || isLoadingUsers;

  return (
    <SadStates states={[
      {
        when: isLoadingPage,
        render: () => <PageLoadingState />
      },
    ]}>
      <FormWrapper<FormCustomer>
        initialValues={initialValues}
        validationSchema={getCustomerUpsertValidationSchema}
        validateOnChange
        validateOnMount
        enableReinitialize={false}
        innerRef={formRef}
        isActionInProgress={isCustomerCreated || isCustomerUpdated}
        primaryButtonLabel={'Submit'}
        onCancel={onCancel}
        onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Grid container className={classes.gridContainer}>
            <CollapsibleButton
              isCollapsed={expandedFormGroup === SHIPPER_FORM_GROUP.GENERAL_INFORMATION}
              text={SHIPPER_FORM_GROUP_LABELS[SHIPPER_FORM_GROUP.GENERAL_INFORMATION]}
              handleOnClick={() => {
                setExpandedFormGroup(
                  expandedFormGroup === SHIPPER_FORM_GROUP.GENERAL_INFORMATION ? null : SHIPPER_FORM_GROUP.GENERAL_INFORMATION
                );
              }}>
              <Grid container direction='column' className={classes.expandableContentContainer}>
                <Grid item direction='row' className={classes.rowContainer}>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6}>
                    <InputField id='name' label='Name' placeholder='Enter shipper name' />
                  </Grid>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6}>
                    <InputField id='code' label='Code' placeholder='Enter shipper code' />
                  </Grid>
                </Grid>
                <Grid item direction='row' className={classes.rowContainer}>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6}>
                    <InputField id='vertical' label='Vertical' placeholder='Enter shipper vertical' />
                  </Grid>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6} />
                </Grid>
                <Grid item direction='row' className={classes.rowContainer}>
                  <Grid className={classes.additionalItem}>
                    <InputField multiline id='notes' inputProps={{ rows: 4 }} label='Notes' placeholder='Leave a note...' />
                  </Grid>
                </Grid>
              </Grid>
            </CollapsibleButton>
            <CollapsibleButton
              isCollapsed={expandedFormGroup === SHIPPER_FORM_GROUP.CONTACT_INFORMATION}
              text={SHIPPER_FORM_GROUP_LABELS[SHIPPER_FORM_GROUP.CONTACT_INFORMATION]}
              handleOnClick={() => {
                setExpandedFormGroup(
                  expandedFormGroup === SHIPPER_FORM_GROUP.CONTACT_INFORMATION ? null : SHIPPER_FORM_GROUP.CONTACT_INFORMATION
                );
              }}>
              <Grid container direction='column' className={classes.expandableContentContainer}>
                <Grid item direction='row' className={classes.rowContainer}>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6}>
                    <InputField id='email' label='Email' placeholder='Enter email address' />
                  </Grid>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6}>
                    <InputField id='contactName' label='Contact Name' placeholder='Enter contact name' />
                  </Grid>
                </Grid>
                <Grid item direction='row' className={classes.rowContainer}>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6}>
                    <InputField id='phone' label='Phone Number' placeholder='Enter phone number' />
                  </Grid>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6}>
                    <InputField id='profitCenter' label='Profit Center' placeholder='Enter profit center' />
                  </Grid>
                </Grid>
                <Grid item direction='row' className={classes.rowContainer}>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6}>
                    <AutocompleteFilter
                      label='NFI Pricing Contact'
                      placeholder='Choose or enter contact'
                      defaultValue={values.nfiPricingContact}
                      options={users}
                      loading={isLoadingUsers}
                      onChange={value => setFieldValue('nfiPricingContact', value || null)}
                      reserveErrorMsgSpace />
                  </Grid>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6} />
                </Grid>
              </Grid>
            </CollapsibleButton>
            <CollapsibleButton
              isCollapsed={expandedFormGroup === SHIPPER_FORM_GROUP.SHIPPER_CONFIGURATION}
              text={SHIPPER_FORM_GROUP_LABELS[SHIPPER_FORM_GROUP.SHIPPER_CONFIGURATION]}
              handleOnClick={() => {
                setExpandedFormGroup(
                  expandedFormGroup === SHIPPER_FORM_GROUP.SHIPPER_CONFIGURATION ? null : SHIPPER_FORM_GROUP.SHIPPER_CONFIGURATION
                );
              }}>
              <Grid container direction='column' className={classes.expandableContentContainer}>
                <Grid item direction='row' className={classes.rowContainer}>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6}>
                    <InputField
                      id='channelId'
                      label='Channel ID'
                      placeholder='Enter channel id' />
                  </Grid>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6}>
                    <AutocompleteFilter
                      label='TMS'
                      placeholder='Choose or enter a new TMS'
                      defaultValue={values.tms}
                      options={tmsOptions}
                      loading={isLoadingCustomers}
                      onChange={value => setFieldValue('tms', value)}
                      freeSolo
                      reserveErrorMsgSpace />
                  </Grid>
                </Grid>
                <Grid item direction='row' className={classes.rowContainer}>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6}>
                    <SelectField
                      placeholder='Choose cost type'
                      id='costType'
                      label='Cost Type'
                      selectedValues={values.costType}
                      options={Object.entries(COST_TYPE_LABELS).map(label => ({id: Number(label[0]), label: label[1]}))} />
                  </Grid>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6}>
                    <InputField
                      id='defaultFuelPriceDelay'
                      label='Default Fuel Price Delay'
                      type='number' />
                  </Grid>
                </Grid>
                <Grid item direction='row' className={classes.rowContainer}>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6}>
                    <InputField
                      id='quoteExpirationPeriod'
                      label='Quote Expiration Period (hours)'
                      type='number' />
                  </Grid>
                </Grid>
                <Grid item direction='row' className={classes.rowContainer}>
                  <Grid item direction='column' xs={12} sm={12} md={6} lg={6}>
                    <Box className={classes.additionalItem}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.autoResponse}
                            onChange={e => setFieldValue('autoResponse', e.target.checked)}
                            name='autoResponse'
                            color='primary'
                            icon={<CheckBoxOutlineBlankTwoToneIcon fontSize='small' viewBox='3 3 18 18' />} />
                        }
                        label='Enable auto response' />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </CollapsibleButton>
          </Grid>
        )}
      </FormWrapper>
    </SadStates>
  );
};