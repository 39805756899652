import { makeStyles } from '@material-ui/core/styles';

import { loginBackgroundImg } from 'resources';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  background: {
    height: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: `linear-gradient(0deg, rgba(5, 100, 179, 0.59) 0%, rgba(5, 100, 179, 0.59) 100%), url(${loginBackgroundImg}), lightgray 50%`,
  },
  contentWrapper: {
    padding: '3.125rem 6.25rem',
    borderRadius: '1rem',
    background: theme.palette.neutral.neutral0,
    [theme.breakpoints.down('xs')]: {
      padding: '3.125rem 1.875rem',
    },
  },
  googleButton: {
    marginTop: '0.625rem',
    padding: '1rem 1.25rem',
    width: '100%',
    borderRadius: '0.125rem',
    background: theme.palette.neutral.neutral0,
    boxShadow:
      '0 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.17), 0 0 0.063rem 0 rgba(0, 0, 0, 0.08)',
  },
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridColumn: {
    flexDirection: 'column',
  },
  inputContainer: {
    marginTop: '1.25rem',
    width: '27.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '18rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '12.5rem',
    },
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0rem 0.3rem 1rem 0rem rgba(0, 0, 0, 0.04)',
    overflow: 'hidden',
    '& > .MuiInputBase-input': {
      padding: '0.5rem !important',
    },
  },
  togglePasswordImg: {
    cursor: 'pointer',
  },
  loginButton: {
    marginTop: '1.875rem',
    width: '100%',
    padding: '1rem 1.25rem',
  },
  errorPopup: {
    marginTop: '1.875rem',
    borderRadius: '0.5rem',
    border: `0.063rem solid ${theme.palette.error.main}`,
    background: theme.palette.error.light,
    '& > div.MuiAlert-icon': {
      display: 'flex',
      alignItems: 'flex-start',
      padding: '0.625rem 0',
      marginRight: '0.75rem',
    },
    '& > div.MuiAlert-action': {
      color: theme.palette.error.main,
      display: 'flex',
      alignItems: 'flex-start',
      padding: '0.3rem 0',
      marginLeft: '1rem',
    },
    width: '25.3rem',
    [theme.breakpoints.down('sm')]: {
      width: '16rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '10.5rem',
    },
  },
  errorPopupText: {
    ...theme.typography.bodyRegularXS,
    color: theme.palette.error.main,
  },
  signUpWithGoogleText: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral400,
    marginLeft: '1.25rem',
    textTransform: 'none',
  },
  separator: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '1.875rem',
  },
  separatorText: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral400,
  },
  dividerStyles: {
    backgroundColor: theme.palette.neutral.neutral400,
    border: '0.03rem',
    width: '45%',
    margin: '0.625rem',
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    paddingBottom: '0.625rem',
  },
  footerText: {
    color: theme.palette.neutral.neutral0,
    textAlign: 'center',
    ...theme.typography.bodyRegularXS,
  },
}));
