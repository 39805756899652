import React from 'react';
import clsx from 'clsx';
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './ModalComponent.css';

type DialogTitleProps = {
  title: string | React.ReactNode,
  onClose: React.MouseEventHandler<HTMLButtonElement>,
  showCloseIcon: boolean,
};

const DialogTitle = ({ title, showCloseIcon, onClose = null }: DialogTitleProps) => {
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.modalTitle}>
      <Typography variant='h4' className={classes.titleText}>{title}</Typography>
      {showCloseIcon && onClose && (
        <IconButton
          aria-label='close'
          disableRipple
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

type ModalComponentProps = {
  message: string | React.ReactNode,
  isOpen: boolean,
  onCancel: () => void,
  children: React.ReactNode,
  modalWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  disableEnforceFocus?: boolean,
  showCloseIcon?: boolean,
  className?: string
};

export const ModalComponent: React.FC<ModalComponentProps> = ({
  message,
  isOpen,
  onCancel,
  children,
  className,
  modalWidth = 'sm',
  disableEnforceFocus = false,
  showCloseIcon = false
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby='dialog-title'
      fullWidth
      maxWidth={modalWidth}
      className={clsx(classes.dialog, className)}
      disableEnforceFocus={disableEnforceFocus}>
      <DialogTitle title={message} onClose={onCancel} showCloseIcon={showCloseIcon} />
      <DialogContent className={classes.content}>
        {children}
      </DialogContent>
    </Dialog>
  );
};