import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyle = makeStyles((theme: ThemeDefault) => ({
  formControl: {
    display: 'grid',
    gap: '0.5rem',
    marginTop: '0.875rem',
    width: '100%',
    '& .MuiInputBase-root': {
      marginTop: 0,
    }
  },
  inputArea: {
    backgroundColor: theme.palette.primary.primary100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1.5rem',
    '& .MuiButtonBase-root': {
      margin: '0 auto',
      width: 'fit-content'
    }
  }
}));
