import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { deliveryIcon, pickupIcon } from 'resources';
import { QuoteDetails as QuoteDetailsType } from 'types';
import { StopsTimeline } from '../StopsTimeline/StopsTimeline';
import { ShipperDetailItem } from '../ShipperDetailItem/ShipperDetailItem';

import { useStyles } from './LocationInfo.css';

type LocationInfoProps = {
  quote: QuoteDetailsType
};

export const LocationInfo = ({ quote } : LocationInfoProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.locationInfo}>
      <Grid container className={classes.pickupDelivery}>
        <Grid item xs={4} className={classes.pickupDeliveryItem}>
          <Box className={classes.iconContainer}>
            <img className={classes.icon} src={pickupIcon} />
            <Typography>Pickup</Typography>
          </Box>
          <Typography variant='h4' className={classes.locationName}>{quote.new_origin}</Typography>
          <Typography className={classes.locationValue}>{quote.new_pickup_date}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.pickupDeliveryItem}>
          <Box className={classes.iconContainer}>
            <img className={classes.icon} src={deliveryIcon} />
            <Typography>Delivery</Typography>
          </Box>
          <Typography variant='h4' className={classes.locationName}>{quote.new_destination}</Typography>
          <Typography className={classes.locationValue}>{quote.new_delivery_date}</Typography>
        </Grid>
      </Grid>
      <Grid container direction='row'>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Distance (miles)'
            value={quote.distance?.toString() || '0'}
            labelSize='sm'
            valueSize='sm' />
        </Grid>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Lane volume (last month)'
            value={quote.historical_market_volumes.volume_1_mo?.toString() || '0'}
            labelSize='sm'
            valueSize='sm' />
        </Grid>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Reverse lane volume (last month)'
            value={quote.historical_market_volumes.reverse_volume_1_mo?.toString() || '0'}
            labelSize='sm'
            valueSize='sm' />
        </Grid>
      </Grid>
      <Box>
        <StopsTimeline stopData={quote?.lane.stops.map((stop) => ({order: stop.order, name: stop.location.name, timeRange: stop.time_range, activityType: stop.activity_type}))} />
      </Box>
    </Box>
  );
};
