import React from 'react';
import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { useHistory } from 'react-router-dom';

import { useStyles } from './NavigationButton.css';

type ActionButtonProps = {
  handleClick?: () => void,
  content?: string,
  urlTo?: string,
  startIcon?: React.ReactNode,
  disableElevation?: boolean,
  size?:	'small' | 'medium' | 'large',
  className?: string | undefined
};

export const NavigationButton = ({
  content,
  className,
  urlTo,
  handleClick,
  size,
}: ActionButtonProps) => {
  const classes = useStyles();
  const history = useHistory();

  const handleButtonClick = () => {
    if (handleClick) {
      handleClick();
    } else if (urlTo) {
      history.push(urlTo);
    }
  };

  return (
    <Button
      onClick={handleButtonClick}
      variant='text'
      disableFocusRipple
      disableElevation
      className={clsx(classes.button, className)}
      startIcon={<FontAwesomeIcon icon={faChevronLeft} size='xs'/>}
      size={size}>
      <Typography variant='h4'>
        {content}
      </Typography>
    </Button>
  );
};