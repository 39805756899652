import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Grid } from '@material-ui/core';

import { InputField, FormWrapper } from 'components';
import { QUOTE_STATUS } from 'enums';
import { useMarkQuoteMutation } from 'services';

import { getValidationSchema } from './validation';

type MarkQuoteWonModalProps = {
  quoteId: number,
  price?: number,
  onCancel: () => void
};

export const MarkQuoteWonModal = ({ quoteId, price, onCancel }: MarkQuoteWonModalProps) => {
  const queryClient = useQueryClient();

  const [ error, setError ] = useState<string>('');
  const [ errorRefNumber, setErrorRefNumber ] = useState<string>('');

  const { mutate: markAsWon, isLoading: isMarkingQuote } = useMarkQuoteMutation(
    queryClient,
    {
      onSuccess: () => {
        onCancel();
        toast.success('Quote successfully marked as Won');
      },
      onError: (error) => {
        if (error?.response) {
          setError(error.response.data.message);
          setErrorRefNumber(error.response.data.messageShipperRefNumber);
        }
      }
    }
  );

  const handleChange = () => {
    error && setError('');
    errorRefNumber && setErrorRefNumber('');
  };

  const handleCancel = () => {
    setError('');
    setErrorRefNumber('');
    onCancel();
  };

  const handleSubmit = ({ loadNumber, shipperRefNumber }: {loadNumber: string, shipperRefNumber: string}) => {
    markAsWon({
      id: quoteId,
      status: QUOTE_STATUS.WON,
      price,
      reason: '',
      load_number: loadNumber.trim() || null,
      shipper_ref_number: shipperRefNumber.trim() || null,
    });
  };

  return (
    <FormWrapper
      initialValues={{ loadNumber: '', shipperRefNumber: '' }}
      validationSchema={getValidationSchema}
      validateOnMount
      isActionInProgress={isMarkingQuote}
      onCancel={handleCancel}
      onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField
            id='loadNumber'
            label='Load Number'
            inputProps={{ maxLength: 30}}
            onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <InputField
            id='shipperRefNumber'
            label='Shipper Shipment ID'
            inputProps={{ maxLength: 30}}
            onChange={handleChange} />
        </Grid>
      </Grid>
    </FormWrapper>
  );
};
