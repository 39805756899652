import React, { SetStateAction } from 'react';
import { FormikErrors } from 'formik';
import { Grid, GridSize } from '@material-ui/core';

import { AutocompleteFilter, DatePickerField, InputField, SelectField } from 'components';
import { fieldConfig } from 'configs';
import { FIELD_TYPE, PAGE_TYPE } from 'enums';
import { Option, UploadAward } from 'types';

import { useCommonStyles } from 'styles';
import { useStyles } from './AwardMetaData.css';

type AwardMetaDataProps = {
  context: PAGE_TYPE;
  customers: Option[],
  errors: FormikErrors<UploadAward>,
  isLoadingCustomer: boolean,
  values: UploadAward,
  setFieldValue: (field: string, value: SetStateAction<string | number | (string | number)[]>) => void,
}

export const AwardMetaData = ({ context, customers, errors, isLoadingCustomer, values, setFieldValue }: AwardMetaDataProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const fields = fieldConfig[context];

  const generateGridProps = (numOfFields: number) => {
    const size = 12 / numOfFields;
    return {
      xs: size as GridSize,
      sm: size as GridSize,
      md: size as GridSize,
      lg: size as GridSize,
    };
  };

  return (
    <>
      <Grid>
        {fields.map((row, rowIndex) => (
          <Grid item key={rowIndex} className={context === PAGE_TYPE.FILE_UPLOAD ? classes.columnContainer : classes.rowContainer}>
            {row.map((field, index) => (
              <Grid item key={index} {...generateGridProps(row.length)}>
                {field.component === FIELD_TYPE.AUTOCOMPLETE && (
                  <AutocompleteFilter
                    label={field.label}
                    placeholder={field.placeholder}
                    error={errors[field.id as keyof UploadAward] as string}
                    options={customers}
                    loading={isLoadingCustomer}
                    onChange={value => setFieldValue(field.id, value)}
                    reserveErrorMsgSpace/>
                )}
                {field.component === FIELD_TYPE.SELECT && (
                  <SelectField
                    id={field.id}
                    className={commonClasses.selectHeight}
                    label={field.label}
                    options={field.options || []}
                    placeholder={field.placeholder}/>
                )}
                {field.component === FIELD_TYPE.DATE && (
                  <DatePickerField
                    id={field.id}
                    label={field.label}
                    disablePast={false}
                    maxDate={values?.expiration_date || undefined}
                    minDate={values?.effective_date || undefined}/>
                )}
                {field.component === FIELD_TYPE.INPUT && (
                  <InputField
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    placeholder={field.placeholder}/>
                )}
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </>
  );
};
