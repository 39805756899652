import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { scrollStyle } from 'src/themeDefault';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  container: {
    ...theme.typography.bodyRegularS,
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0.3rem 0rem',
  },
  secondaryText: {
    color: theme.palette.neutral.neutral400,
  },
  text: {
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    whiteSpace: 'nowrap',
  },
  link: {
    color: theme.palette.primary.primary700,
    '&:hover': {
      color: theme.palette.primary.primary500,
      cursor: 'pointer',
    }
  },
  rowContainer: {
    flexDirection: 'column',
  },
  icon: {
    alignItems: 'end',
    display: 'grid',
    fontSize: '1.125rem',
    verticalAlign: 'middle',
  },
  triggered: {
    color: theme.palette.success.main,
  },
  notTriggered: {
    color: theme.palette.neutral.neutral400,
  },
  scrollableContent: {
    overflowX: 'hidden',
    padding: '0.25rem',
    ...scrollStyle as CSSProperties,
  },
  selectItem: {
    color: theme.palette.neutral.neutral400,
    height: '2.7rem',
    marginLeft: 'auto',
    width: '100%',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '0.825rem !important',
  },
}));
