import React from 'react';
import { Divider, Skeleton, Typography } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './WidgetStates.css';

type WidgetNoResultsStateProps = {
  message: string;
  className?: string;
};

export const WidgetLoadingState = () => {
  const classes = useStyles();
  return (
    <>
      {[...Array(5)].map((_, index) => (
        <div key={index}>
          <Skeleton className={classes.skeleton}/>
          <Divider/>
        </div>
      ))}
    </>
  );
};

export const WidgetNoResultsState = ({ message, className }: WidgetNoResultsStateProps) => {
  const classes = useStyles();
  return <Typography className={clsx(classes.message, className)}>{message}</Typography>;
};