import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  title: {
    ...theme.typography.bodyRegularS,
    marginBottom: '0.5rem',
  },
  field: {
    width: '100%',
  },
  formControl: {
    borderRadius: '0.5rem',
    width: '100%',
    '& .MuiFormControl-root': {
      position: 'unset',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.primary200}`,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.primary700}`,
    },
    '& > div.MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        border: `1px solid ${theme.palette.primary.primary700}`,
        borderWidth: 1,
      },
    },
    '& .MuiFormHelperText-root.Mui-error': {
      ...theme.typography.bodyRegularXS,
      color: theme.palette.error.main,
    },
  },
  input: {
    ...theme.typography.bodyRegularS,
    backgroundColor: theme.palette.neutral.neutral0,
    borderRadius: '0.5rem',
    color: theme.palette.neutral.neutral700,
    padding: '0.5rem 1rem',
    width: '100%',
    '& > .MuiInputBase-input': {
      padding: 2,
    },
    '& input::placeholder': {
      color: theme.palette.neutral.neutral400,
      opacity: 1,
    },
    '& textarea::placeholder': {
      color: theme.palette.neutral.neutral400,
      opacity: 1,
    },
  },
  startAdornment: {
    marginLeft: '0.5rem',
    width: '0.75rem',
  },
  endAdornment: {
    marginRight: '0.5rem',
    '& .MuiSelect-root': {
      ...theme.typography.bodyRegularS,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.palette.neutral.neutral0,
    },
  },
  errorMsg: {
    ...theme.typography.bodyRegularXS,
    color: theme.palette.error.main,
    height: '1rem'
  },
}));