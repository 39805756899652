import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import {
  MESSAGE_BOX_TYPE
} from 'enums';
import { infoIcon, redExclamationIcon, warningIcon} from 'resources';

import { useStyles } from './MessageBox.css';

interface MessageBoxProps {
    type?: MESSAGE_BOX_TYPE;
    message: string | React.ReactNode;
}

export const MessageBox = ({ type = MESSAGE_BOX_TYPE.INFO, message }: MessageBoxProps) => {
  const classes = useStyles();

  const messageIcon = {
    info: infoIcon,
    warning: warningIcon,
    error: redExclamationIcon,
  }[type];

  return (
    <Typography variant='h5' className={clsx(
      classes.defaultBox,
      {
        [classes.infoBox]: type === MESSAGE_BOX_TYPE.INFO,
        [classes.warningBox]: type === MESSAGE_BOX_TYPE.WARNING,
        [classes.errorBox]: type === MESSAGE_BOX_TYPE.ERROR,
      }
    )}>
      <img src={messageIcon} alt={`${type} icon`}/>
      {message}
    </Typography>
  );
};