import _ from 'lodash';

import { DEFAULT_FILTER_VALUES, FILTER_PAGE_SOURCE } from 'enums';
import { FilterConfigType, FilterParamsType, FilterSearchParamsType, Option } from 'types';

export const addSelectAllOption = (options: Option[], selectAllLabel: string) => [{ id: 'all', label: selectAllLabel }, ...options ];

export const sortOptions = (options: Option[]) => options.sort((a: Option, b: Option) => a.label?.localeCompare(b.label));

export const calculateNumberOfFilterChanges = (filterConfig: FilterConfigType[], paramsObject: FilterParamsType) => {
  let counter = 0;
  for (const [ key, value ] of Object.entries(paramsObject)) {
    const elementFromCofig = filterConfig.find(configEl => configEl.paramsProperty === key);
    const areJsonsEqual = typeof(value) === 'object' && _.isEqual(value, elementFromCofig.initialValue);
    if (elementFromCofig && JSON.stringify(value) !== JSON.stringify(elementFromCofig.initialValue) && !areJsonsEqual) {
      counter++;
    }
  }
  return counter;
};

export const getPageAppliedFilters = (filterPageSource: FILTER_PAGE_SOURCE) => {
  let appliedFilters = JSON.parse(localStorage.getItem('appliedFilters'));
  // If no applied filters are found in localStorage, initialize them with DEFAULT_FILTER_VALUES without page refresh
  if (!appliedFilters) {
    localStorage.setItem('appliedFilters', JSON.stringify(DEFAULT_FILTER_VALUES));
    appliedFilters = DEFAULT_FILTER_VALUES;
  }
  return appliedFilters[filterPageSource];
};

export const setPageAppliedFilters = (filterPageSource: FILTER_PAGE_SOURCE, search?: string, newValues?: FilterParamsType) => {
  const newAppliedFilters = {...JSON.parse(localStorage.getItem('appliedFilters'))};
  const newSearch = search ?? newValues?.search;
  newAppliedFilters[filterPageSource] = {...newValues, search: newSearch};
  localStorage.setItem('appliedFilters', JSON.stringify(newAppliedFilters));
};

export const getAppliedFilters = (appliedFiltersAndSearch: FilterSearchParamsType): FilterParamsType => {
  const appliedFilterKeys = Object.keys(appliedFiltersAndSearch)?.filter(filterName => filterName !== 'search');
  return appliedFilterKeys.reduce((obj: FilterParamsType, key: string) => {
    return {
      ...obj,
      [key]: appliedFiltersAndSearch[key]
    } as FilterParamsType;
  }, {});
};

export const updateAppliedFiltersIfNeeded = () => {
  //Add missing filters from DEFAULT_FILTER_VALUES to localStorage on page refresh
  const appliedFilters = JSON.parse(localStorage.getItem('appliedFilters'));

  let updated = false;
  (Object.keys(DEFAULT_FILTER_VALUES) as (keyof typeof DEFAULT_FILTER_VALUES)[]).forEach(key => {
    if (!(key in appliedFilters)) {
      appliedFilters[key] = DEFAULT_FILTER_VALUES[key];
      updated = true;
    }
  });

  if (updated) {
    localStorage.setItem('appliedFilters', JSON.stringify(appliedFilters));
  }
};
