import { ThemeDefault } from 'types';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  activitiTypeLabel: {
    color: theme.palette.grey[500],
    fontStyle: 'italic',
    paddingLeft: '0.3rem',
  },
  hideConnector: {
    display: 'none'
  },
  timelineContainer: {
    paddingLeft: 0,
    '& li': {
      minHeight: '3rem'
    },
    '& li:before': {
      flex: 0,
      padding: 0
    }
  },
  timelineSeparator: {
    transform: 'translate(0, 10px)',
    '& .MuiTimelineDot-root': {
      backgroundColor: theme.palette.primary.primary700,
      margin: 0,
      padding: '2px'
    },
    '& .MuiTimelineConnector-root': {
      backgroundColor: 'transparent',
      border: `1px dashed ${theme.palette.grey[500]}`,
      width: '0px',
    }
  },
  timelineContent: {
    ...theme.typography.bodyRegularS,
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
  },
  dateTimeRange: {
    ...theme.typography.bodyXXS,
    color: theme.palette.text.secondary,
    marginTop: '0.5rem',
    textAlign: 'left',
    width: '100%',
  }
}));
