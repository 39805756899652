import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { Grid } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';

import { FormWrapper, PageLoadingState, SadStates, UploadFileField } from 'components';
import { FAILED_TO_UPLOAD_FILE_MESSAGE, FILE_CATEGORY, PAGE_TYPE, SUCCESSFULLY_UPLOADED_FILE_MESSAGE, UPLOAD_CSV_FILE_TITLE } from 'enums';
import { getUploadAwardMetaDataInitialValues } from 'helpers';
import { AwardMetaData } from 'pages';
import { useEditAwardMutation, useGetAwardById, useGetCustomersQuery, useImportFileMutation } from 'services';
import { Customer, QueryResult, UpdateAward, UploadAward } from 'types';
import { getUploadAwardValidationSchema } from 'validations';

import { useStyles } from './AwardUpsertForm.css';

type AwardUpsertFormProps = {
  id: string;
  onCancel: () => void,
}

type AwardFormType<T extends number | undefined> = T extends number ? UploadAward : UpdateAward;

export const AwardUpsertForm = ({ id, onCancel }: AwardUpsertFormProps) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const awardId = Number(id);

  const { isLoading: isAwardLoading, data: awardData } = useGetAwardById(awardId);
  const { data: award } = awardData || {};

  const { isLoading: isLoadingCustomer, data: customerData }: QueryResult<AxiosResponse<Customer[]>> = useGetCustomersQuery();
  const customers = customerData?.data?.map((c: Customer) => {
    return { id: c.id, label: c.customer_name };
  }) || [];

  const { mutate: importFile, isLoading: isUploadingAward } = useImportFileMutation({
    onSuccess: () => {
      onCancel();
      toast.success(SUCCESSFULLY_UPLOADED_FILE_MESSAGE);
    },
    onError: () => {
      toast.error(FAILED_TO_UPLOAD_FILE_MESSAGE);
    }
  });

  const { mutate: editAward, isLoading: isEditingAward } = useEditAwardMutation(queryClient, {
    onSuccess: () => {
      onCancel();
      toast.success('Award successfully edited.');
    },
    onError: (error) => {
      if (error?.response?.data) {
        toast.error(error.response.data.error);
      }
    }
  });

  const initialValues = {
    file: null as File,
    ...getUploadAwardMetaDataInitialValues(award),
  };

  const handleImportAwards = (values: UploadAward) => {
    const formData = new FormData();
    formData.append('file', values.file);
    formData.append('name', values.name);
    formData.append('type', values.file.type);
    formData.append('category', String(FILE_CATEGORY.AWARD));
    formData.append('customer', values.customer);
    formData.append('region', values.region);
    formData.append('effective_date', format(new Date(values.effective_date), 'yyyy-MM-dd'));
    formData.append('expiration_date', format(new Date(values.expiration_date), 'yyyy-MM-dd'));
    importFile(formData);
  };

  const handleUpdateAward = (values: UpdateAward) => {
    const payload = {
      name: values.name,
      region: values.region,
      effective_date: format(new Date(values.effective_date), 'yyyy-MM-dd'),
      expiration_date: format(new Date(values.expiration_date), 'yyyy-MM-dd'),
    };

    editAward({ id: award.id, payload});
  };

  return (
    <SadStates states={[
      {
        when: isAwardLoading || isLoadingCustomer,
        render: () => <PageLoadingState/>
      },
    ]}>
      <FormWrapper<AwardFormType<typeof awardId>>
        initialValues={initialValues}
        validationSchema={getUploadAwardValidationSchema(awardId)}
        validateOnMount
        enableReinitialize={false}
        isActionInProgress={isUploadingAward || isEditingAward}
        primaryButtonLabel='Submit'
        onCancel={onCancel}
        onSubmit={awardId ? handleUpdateAward : handleImportAwards}>
        {({ errors, values, setFieldValue }) => (
          <Grid container direction='column' spacing={4} zeroMinWidth>
            {!awardId &&
              <Grid item direction='row' xs>
                <UploadFileField
                  id='file'
                  accept='.csv, .xlsx'
                  className={classes.uploadContainer}
                  title={UPLOAD_CSV_FILE_TITLE} />
              </Grid>}
            <AwardMetaData
              context={awardId ? PAGE_TYPE.EDIT : PAGE_TYPE.CREATE}
              customers={customers}
              errors={errors}
              isLoadingCustomer={isLoadingCustomer}
              values={values}
              setFieldValue={setFieldValue} />
          </Grid>
        )}
      </FormWrapper>
    </SadStates>
  );
};
