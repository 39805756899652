import React, { useEffect, useState } from 'react';

import { CollapsibleWidget} from 'components';
import { useRuleExecutionLog } from 'contexts';
import { MODAL_CONTENT_TYPE } from 'enums';
import { RuleExecutionLog, RuleExecutionLogModal } from 'pages';
import { ArrowUpRightSquareIcon } from 'resources';
import { QuoteExecutionLogData, RulesetExecutionLog } from 'types';

import { useStyles } from './RuleExecutionLogWidget.css';

type RuleExecutionLogWidgetProps = {
    rulesetExecutionLogs: RulesetExecutionLog<QuoteExecutionLogData>[]
  };

export const RuleExecutionLogWidget = ({ rulesetExecutionLogs } : RuleExecutionLogWidgetProps) => {
  const classes = useStyles();

  const [ isRuleExecutionLogModalOpen, setIsRuleExecutionLogModalOpen ] = useState(false);
  const { setModalContent, setRulesetExecutionLogs } = useRuleExecutionLog();

  useEffect(() => {
    setRulesetExecutionLogs(rulesetExecutionLogs);
  }, []);

  const handleIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsRuleExecutionLogModalOpen(true);
    setModalContent(MODAL_CONTENT_TYPE.TABLE);
  };

  return (
    <>
      <CollapsibleWidget
        headerTitle={
          <span className={classes.title}>
            Latest rule execution log
            {rulesetExecutionLogs?.length > 1 && (
              <ArrowUpRightSquareIcon className={classes.icon} onClick={(e) => handleIconClick(e)}/>
            )}
          </span>
        }>
        <RuleExecutionLog ruleExecutionLog={rulesetExecutionLogs?.[0]} className={classes.rulesContainer}/>
      </CollapsibleWidget>
      <RuleExecutionLogModal
        isOpen={isRuleExecutionLogModalOpen}
        onCancel={() => setIsRuleExecutionLogModalOpen(false)}/>
    </>
  );
};