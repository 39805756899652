import React, { useContext, useState } from 'react';

import { ExpressionEditorConfig, QuoteExecutionLogData, Rule, RulesetExecutionLog } from 'types';

type RuleExecutionLogProviderProps = {
  children: React.ReactNode;
}

const RuleExecutionLogContext = React.createContext(null);

export const useRuleExecutionLog = () => {
  return useContext(RuleExecutionLogContext);
};

export const RuleExecutionLogProvider = ({ children }: RuleExecutionLogProviderProps) => {
  const [ modalContent, setModalContent ] = useState(null);
  const [ orderedRuleset, setOrderedRuleset ] = useState<Rule[]>(null);
  const [ ruleEditorConfiguration, setRuleEditorConfiguration ] = useState<ExpressionEditorConfig>(null);
  const [ rulesetExecutionLogs, setRulesetExecutionLogs ] = useState<RulesetExecutionLog<QuoteExecutionLogData>[]>([]);
  const [ selectedRule, setSelectedRule ] = useState<Rule>(null);

  const values = {
    modalContent,
    orderedRuleset,
    ruleEditorConfiguration,
    rulesetExecutionLogs,
    selectedRule,
    setModalContent,
    setOrderedRuleset,
    setRuleEditorConfiguration,
    setRulesetExecutionLogs,
    setSelectedRule
  };

  return (
    <RuleExecutionLogContext.Provider value={values}>
      {children}
    </RuleExecutionLogContext.Provider>
  );
};
