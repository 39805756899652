/* Utility types */
import { JSONField } from './CommonTypes';

import { JsonLogicTree } from '@react-awesome-query-builder/core';
import { Identified} from './IndentifiedTypes';
import { FieldSettings } from '@react-awesome-query-builder/ui';

type Optional<T> = T | null;

interface Prioritized {
  priority: number;
}

interface ExpressionNodeBase extends Identified, Prioritized {}

export type Value = Optional<string | number | boolean>;

export enum ExpressionType {
  LITERAL = 'literal',
  VARIABLE = 'variable',
  OPERATION = 'operation',
}

export interface Literal extends ExpressionNodeBase {
  type: ExpressionType.LITERAL;
  value: Value;
}

export interface Variable extends ExpressionNodeBase {
  type: ExpressionType.VARIABLE;
  value: string;
}

export interface Operation extends ExpressionNodeBase {
  type: ExpressionType.OPERATION;
  name: Optional<string>;
  args: (Literal | Variable | Operation)[];
}

export type Expression = Literal | Variable | Operation;

export interface Setter extends Action {
  action_data: {
    expression: Expression;
  };
}

export interface Action extends Prioritized {
  name: string;
  action_data: Record<string, unknown> | null;
}

export interface Rule extends Prioritized, Identified {
  name: string;
  order: string;
  test: JsonLogicTree;
  actions: Action[];
}

export type Ruleset = Rule[];

/* Expression Editor config */

export enum ValueType {
  NUMBER = 'number',
  STRING = 'string',
  BOOLEAN = 'boolean',
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'datetime'
}

export enum ActionType {
  SETTER = 'setter',
  STOP_RULE_GROUP_EXECUTION = 'stop-rule-group-execution',
  STOP_RULESET_EXECUTION = 'stop-ruleset-execution',
  STOP_RULESET_EXECUTION_NO_REPLY = 'stop-ruleset-execution-no-reply',
  STOP_RULESET_EXECUTION_REJECT = 'stop-ruleset-execution-reject'
}

export interface AsyncFetchConfig {
  url: string; // API endpoint with placeholders for input, limit, and offset
  trigger_search_after?: number | null; // Number of characters to trigger search after
  limit?: number | null; // Maximum number of records to retrieve
  ordering?: string | null, // ordering results by field
  value_field?: string | null; // The field to use as the value in the result
  title_field?: string | null; // The field to use as the title in the result
  valueFunc?: string | null; // The field to use as the function for getting value in the result
  titleFunc?: string | null; // The field to use as the function for getting title in the result
}

export interface OperandConfigBase {
  fieldSettings?: FieldSettings;
  customSettings?: Record<string, unknown>;
}

export interface OperandConfig {
  type: ValueType;
  name: string;
  display_name: string;
  is_precondition_operand: boolean;
  is_expression_operand: boolean;
  choices?: Record<string, string | number | boolean>;
  config?: OperandConfigBase;
}

export interface OperationConfig {
  name: string;
  display_name: string;
  arg_count: Optional<number | 'unlimited'>;
  result_type: ValueType;
}

export interface SetterConfig {
  result_type: string;
  field_name: string;
  choices: Record<string, string|number> | null
}

export interface ActionConfig {
  name: string;
  display_name: string;
  action_type: ActionType;
  config: SetterConfig | null
}

export interface ExpressionEditorConfig {
  operands: OperandConfig[];
  operations: OperationConfig[];
  actions: ActionConfig[]
}

/* Expression editor component props */

export type ExpressionUpdateCb = (ex: Expression) => void;

export interface ExpressionEditorBaseProps {
  resultType: ValueType;
  config: ExpressionEditorConfig;
  onUpdate: ExpressionUpdateCb;
  isReadOnly?:boolean
}

export type ContextValueType = string | number | boolean | Date | null

export type ExecutionContex = {
  [key: string] : ContextValueType
}

export type ContextChange = {
  field_name: string,
  old_value: ContextValueType,
  new_value: ContextValueType,
}

export type ActionLog = {
  [key: string] : string | JSONField,
  display_name: string;
  action_type: ActionType;
  context_change: ContextChange | null;
}

export type RuleActionLogs = {
  [key: string] : ActionLog[],
}

export type RulesetExecutionLog<T> = {
  created_at: string,
  title: string,
  ruleset: Ruleset,
  context: ExecutionContex,
  result: ContextValueType,
  triggered_rules: (string | number)[],
  rule_action_logs?: RuleActionLogs | null,
  additional_data: T,
};

export type QuoteExecutionLogData = {
  quote_id: number,
  shipper_id: number,
  cost_type?: number,
  rule_execution_context?: string,
}