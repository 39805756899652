import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  headerWrapper: {
    '&.MuiGrid-container': {
      marginBottom: '2rem'
    }
  },
  headerFilters: {
    '& > div:first-of-type': {
      width: '18rem',
      marginRight: '1rem',
    },
    '& > div:not(:first-child)': {
      width: '16rem',
      marginRight: '1rem',
    },
    '& > div > div': {
      width: '100%'
    }
  },
  headerButton: {
    height: '40px'
  },
  title: {
    ...theme.typography.bodyMediumS,
    marginTop: 20,
    marginBottom: 4,
    color: '#374151'
  },
  workingCopiesHeaderButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonRight: {
    textAlign: 'right'
  },
  iconButton: {
    padding: '0.75rem !important',
  },
  buttonContainer: {
    '& .MuiPaper-root': {
      '& .MuiIconButton-root': {
        padding: '0.55rem !important',
      },
    },
  },
  errorMsg: {
    color: 'red',
    fontSize: 'small'
  },
  builderOverrides: {
    '& .MuiIconButton-root': {
      padding: 0
    },

    '& .MuiSelect-select': {
      padding: '0 24px 0 0 !important',
      minHeight: '1.5rem'
    },

    '& .MuiFormLabel-root': {
      ...theme.typography.bodyRegularS,
      padding: '6px 6px 0 6px !important',
    },
    '& .MuiFormControlLabel-root': {
      '& .MuiTypography-root': {
        ...theme.typography.bodyRegularS,
      },
      padding: '6px 0 6px 14px !important',
    },
    '& .MuiPaper-root': {
      border: `1px solid ${theme.palette.primary.primary700} !important`,
      borderRadius: '0.5rem',
    },

    '& .group-or-rule-container': {
      boxShadow: '0 0.25rem 1rem 0 rgba(0, 0, 0, 0.04)',
    },
    '& .group': {
      padding: '6px !important',
      background: `${theme.palette.primary.primary100} !important`,
      border: `1px solid ${theme.palette.primary.primary500} !important`,
      borderRadius: '0.5rem',
    },
    '& .group--conjunctions': {
      '& .MuiButtonBase-root': {
        marginRight: '0.5rem',
        borderRadius: '0.3125rem',
        fontWeight: 500,
      },
      '& .MuiButton-containedPrimary': {
        border: `1px solid ${theme.palette.primary.primary700} !important`,
        background: `${theme.palette.primary.primary700} !important`,
        color: `${theme.palette.neutral.neutral0} !important`,
        '&:hover': {
          background: `${theme.palette.primary.primary500} !important`,
        }
      },
      '& .MuiButton-containedNeutral': {
        border: `1px solid ${theme.palette.secondary.secondary500} !important`,
        background: `${theme.palette.primary.primary100} !important`,
        color: `${theme.palette.secondary.secondary500} !important`,
        '&:hover': {
          background: `${theme.palette.primary.primary200} !important`,
        }
      },
      '& .MuiButton-containedSecondary': {
        border: `1px solid ${theme.palette.secondary.secondary700} !important`,
        background: `${theme.palette.secondary.secondary700} !important`,
        color: `${theme.palette.neutral.neutral0} !important`,
        '&:hover': {
          background: `${theme.palette.secondary.secondary500} !important`,
        }
      },
    },

    '& .group--actions': {
      '& .MuiButtonBase-root': {
        marginRight: '0.5rem',
        fontWeight: 500,
      },
      '& .MuiButton-textNeutral': {
        color: theme.palette.neutral.neutral700,
      },
      '& .MuiButton-textPrimary': {
        color: theme.palette.primary.primary700,
      },
    },

    '& .MuiIconButton-colorSecondary': {
      '& svg': {
        '& path': {
          fill: theme.palette.secondary.secondary500
        },
      },
      '&:hover': {
        '& svg': {
          '& path': {
            fill: theme.palette.primary.primary700
          },
        },
      }
    },

    '& .MuiInputBase-input': {
      padding: '1px 0 5px'
    }
  }
}));