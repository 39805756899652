import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  container: {
    ...theme.typography.bodyRegularS,
    padding: '0.3rem 0rem'
  },
  secondaryText: {
    color: theme.palette.neutral.neutral400
  },
  headerText: {
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
}));