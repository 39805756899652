import React from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormControl, Grid } from '@material-ui/core';
import { AxiosResponse } from 'axios';

import { AutocompleteFilter, FormWrapper, InputField, PageLoadingState, SadStates, SelectField, UploadFileField } from 'components';
import { FAILED_TO_UPLOAD_FILE_MESSAGE, FILE_CATEGORY, SUCCESSFULLY_UPLOADED_FILE_MESSAGE, TIME_FRAME_TYPES_LABELS, UPLOAD_XLSX_FILE_TITLE } from 'enums';
import { useEditWorkingCopyMutation, useGetCustomersQuery, useGetUsersQuery, useGetWorkingCopyById, useImportFileMutation } from 'services';
import { Customer, Params, QueryResult, UpdateWorkingCopy, WorkingCopyForm } from 'types';
import { getUploadWorkingCopyValidationSchema } from 'validations';

import { useStyles } from './UploadWorkingCopyForm.css';

const MAX_FILE_SIZE = 31457280;

type UploadWorkingCopyFormProps = {
  onCancel: () => void,
}

type WorkingCopyFormType<T extends number | undefined> = T extends number ? UpdateWorkingCopy : WorkingCopyForm;

export const UploadWorkingCopyForm = ({ onCancel }: UploadWorkingCopyFormProps) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { id } = useParams<Params>();
  const workingCopyId = Number(id);

  const { isLoading: isWorkingCopyLoading, data: workingCopyData } = useGetWorkingCopyById(workingCopyId);
  const { data: workingCopy } = workingCopyData || {};

  const { isLoading: isLoadingUsers, data: userData } = useGetUsersQuery();
  const users = userData?.data?.map((c) => {
    return { id: c.id, label: c.display_name };
  }) || [];

  const { isLoading: isLoadingCustomer, data: customerData }: QueryResult<AxiosResponse<Customer[]>> = useGetCustomersQuery();
  const customers = customerData?.data?.map((c) => {
    return { id: c.id, label: c.customer_name };
  }) || [];

  const { mutate: importFile, isLoading: isWorkingCopyImporting } = useImportFileMutation({
    onSuccess: () => {
      onCancel();
      toast.success(SUCCESSFULLY_UPLOADED_FILE_MESSAGE);
    },
    onError: () => {
      toast.error(FAILED_TO_UPLOAD_FILE_MESSAGE);
    }
  });

  const { mutate: editWorkingCopy, isLoading: isWorkingCopyUpdating } = useEditWorkingCopyMutation(queryClient, {
    onSuccess: () => {
      onCancel();
      toast.success('Working copy successfully edited.');
    },
    onError: (error) => {
      if (error?.response?.data) {
        toast.error(error.response.data.error);
      }
    }
  });

  const handleImportWorkingCopy = (values: WorkingCopyForm) => {
    if (values.file.size > MAX_FILE_SIZE) {
      toast.error('Uploaded file is too large');
      return;
    }
    const formData = new FormData();
    formData.append('file', values.file);
    formData.append('name', values.name);
    formData.append('type', values.file.type);
    formData.append('category', String(FILE_CATEGORY.WORKING_COPY));
    formData.append('round', values.round);
    formData.append('customer', values.customer);
    formData.append('account_manager', values.account_manager);
    formData.append('time_frame', values.time_frame);
    importFile(formData);
  };

  const handleUpdateWorkingCopy = (values: UpdateWorkingCopy) => {
    const payload = {
      name: values.name || '',
      customer: values.customer,
      round: Number(values.round),
      account_manager: values.account_manager,
      time_frame: values.time_frame
    };
    editWorkingCopy({id: workingCopy.id, payload});
  };

  return (
    <SadStates states={[
      {
        when: isWorkingCopyLoading || isLoadingCustomer || isLoadingUsers,
        render: () => <PageLoadingState/>
      },
    ]}>
      <FormWrapper<WorkingCopyFormType<typeof workingCopyId>>
        initialValues={{
          file: null,
          name: workingCopy ? workingCopy.name : '',
          customer: workingCopy ? workingCopy.customer_id : '',
          round: workingCopy ? workingCopy.round : '',
          time_frame: workingCopy ? workingCopy.time_frame : '',
          account_manager: workingCopy ? workingCopy.account_manager_id : '',
        }}
        validationSchema={getUploadWorkingCopyValidationSchema(workingCopyId)}
        validateOnMount
        enableReinitialize={false}
        isActionInProgress={workingCopyId ? isWorkingCopyUpdating : isWorkingCopyImporting}
        primaryButtonLabel='Submit'
        onCancel={onCancel}
        onSubmit={workingCopyId ? handleUpdateWorkingCopy : handleImportWorkingCopy}>
        {({ errors, setFieldValue }) => (
          <Grid container direction='column' spacing={4} zeroMinWidth>
            {!workingCopyId &&
              <Grid item direction='row' xs={12} sm={12} md={12} lg={12}>
                <UploadFileField
                  id='file'
                  accept='.csv, .xlsx'
                  className={classes.uploadContainer}
                  title={UPLOAD_XLSX_FILE_TITLE} />
              </Grid>}
            <Grid item direction='row' className={classes.rowContainer}>
              <Grid item direction='column' xs={12} sm={12} md={12} lg={12}>
                <InputField
                  id='name'
                  label='Working copy name'
                  type='text'
                  placeholder='Enter working copy name'/>
              </Grid>
            </Grid>
            <Grid item direction='row' className={classes.rowContainer}>
              <Grid direction='column' xs={12} sm={12} md={6} lg={6}>
                <FormControl error={!!errors['customer']}>
                  <AutocompleteFilter
                    label='Shipper'
                    placeholder='Choose or enter shipper partner'
                    error={errors?.customer as string}
                    options={customers}
                    loading={isLoadingCustomer}
                    onChange={value => setFieldValue('customer', value)}
                    defaultValue={workingCopy ? workingCopy.customer_id : ''}
                    reserveErrorMsgSpace />
                </FormControl>
              </Grid>
              <Grid direction='column' xs={12} sm={12} md={6} lg={6}>
                <InputField
                  id='round'
                  label='Round'
                  type='number'
                  placeholder='Enter round number'/>
              </Grid>
            </Grid>
            <Grid item direction='row' className={classes.rowContainer}>
              <Grid direction='column' xs={12} sm={12} md={6} lg={6}>
                <FormControl error={!!errors['account_manager']}>
                  <AutocompleteFilter
                    label='Account Manager'
                    placeholder='Choose account manager'
                    error={errors?.account_manager as string}
                    options={users}
                    loading={isLoadingUsers}
                    onChange={value => setFieldValue('account_manager', value)}
                    defaultValue={workingCopy ? workingCopy.account_manager_id : ''}
                    reserveErrorMsgSpace />
                </FormControl>
              </Grid>
              <Grid direction='column' xs={12} sm={12} md={6} lg={6}>
                <FormControl error={!!errors['time_frame']}>
                  <SelectField
                    id='time_frame'
                    label='Time Frame'
                    options={Object.keys(TIME_FRAME_TYPES_LABELS).map((key: string) => {
                      return {id: key, label: TIME_FRAME_TYPES_LABELS[key]};
                    })}
                    selectedValues={workingCopy ? `${workingCopy.time_frame}` : ''}
                    placeholder='Choose time frame' />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}
      </FormWrapper>
    </SadStates>
  );
};
