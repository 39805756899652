import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  icon: {
    cursor: 'pointer',
    fill: theme.palette.neutral.neutral700,
    marginLeft: '0.1rem',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    gap: '0.5rem',
  },
  rulesContainer: {
    maxHeight: '15rem',
  },
}));
