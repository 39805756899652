import React from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Layout } from 'components';
import { FILTER_PAGE_SOURCE } from 'enums';
import { CUSTOMER_PERMISSIONS } from 'permissions';
import {
  useGetAllMaxBuyRulesById,
  useGetCustomerById,
  useUpdateCustomerMaxBuyRulesMutation
} from 'services';
import { Params, Ruleset } from 'types';
import { RuleTable } from '../components/RuleTable';
import { getPageAppliedFilters } from 'helpers';

export const MaxBuyRulesPage = () => {
  const queryClient = useQueryClient();
  const { id } = useParams<Params>();

  const appliedFiltersAndSearch = getPageAppliedFilters(FILTER_PAGE_SOURCE.SHIPPER_MAX_BUY_RULE);

  const { isSuccess: isCustomerSuccess, isError: isCustomerError, isFetching: isCustomerFetching, data: customerData } = useGetCustomerById(id);
  const { data: customer } = customerData || {};
  const { isSuccess: isMaxBuyRulesSuccess, isError: isMaxBuyRulesError, isFetching: isMaxBuyRulesFetching, data: rulesData} = useGetAllMaxBuyRulesById(customer?.id);
  const { data: rules } = rulesData || {};

  const { mutate: updateCustomerMaxBuyRules, isLoading: isCustomerMaxBuyRuleUpdating} = useUpdateCustomerMaxBuyRulesMutation(queryClient, {
    onSuccess: () => {
      toast.success('Max Buy rules successfully updated!');
    },
    onError: (error) => {
      toast.error('Max Buy rules unsuccessfully updated!');
      console.error(error);
    }
  });

  return (
    <Layout
      goBackTo='/shippers'
      title={`${isCustomerSuccess ? `${customer.customer_name} - ` : ''}Max Buy Rules`}>
      <RuleTable
        isSuccess={isMaxBuyRulesSuccess}
        isFetching={isCustomerFetching || isMaxBuyRulesFetching}
        isError={isCustomerError || isMaxBuyRulesError}
        isLoading={isCustomerMaxBuyRuleUpdating}
        ruleEditorConfig={rulesData?.data?.rule_editor_config}
        ruleset={rules?.max_buy_price_prediction_rules}
        handleSaveChanges={(rules: Ruleset) => updateCustomerMaxBuyRules({ id: customer?.id, max_buy_price_prediction_rules: rules })}
        permissions={[CUSTOMER_PERMISSIONS.MAX_BUY_RULES_UPDATE]}
        appliedFiltersAndSearch={appliedFiltersAndSearch}
        filterPageSource={FILTER_PAGE_SOURCE.SHIPPER_MAX_BUY_RULE}/>
    </Layout>
  );
};