import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { CollapsibleWidget, SadStates, WidgetLoadingState, WidgetNoResultsState } from 'components';
import { CURRENCY_TYPE_LABEL, DEFAULT_DISPLAY_VALUE } from 'enums';
import { usePricePredictionQuery } from 'services';
import { Params, QuotePricePrediction } from 'types';

import { useStyles } from './PricePrediction.css';

type PricePredictionProps = {
  currency: string
};

export const PricePrediction = ({ currency } : PricePredictionProps) => {
  const classes = useStyles();
  const { id } = useParams<Params>();

  const { isError, isLoading, isSuccess, data } = usePricePredictionQuery(Number(id));
  const { data: pricePrediction } = data || {};

  const fields: { label: string, field: keyof QuotePricePrediction }[] = [
    { label: 'Cost estimate:', field: 'cost_estimate' },
    { label: 'Markup total:', field: 'markup_percentage_total' },
    { label: 'All-in:', field: 'all_in_price_prediction' },
    { label: 'Linehaul:', field: 'linehaul_price_prediction' },
    { label: 'Fuel:', field: 'fuel_surcharge' },
  ];

  const formatValue = (value: number | null, isPercentage: boolean) => {
    if (value === null) {
      return DEFAULT_DISPLAY_VALUE;
    }

    const roundedValue = Math.round(value);
    return isPercentage ? `${roundedValue} %` : `${CURRENCY_TYPE_LABEL[currency]}${roundedValue}`;
  };

  return (
    <CollapsibleWidget headerTitle='Price Prediction'>
      <SadStates states={[
        {
          when: isSuccess && Object.values(pricePrediction).every(value => value === null),
          render: () => <WidgetNoResultsState message='No price prediction available.'/>
        },
        {
          when: isError,
          render: () => <WidgetNoResultsState message='Something went wrong when trying to get price prediction.'/>
        },
      ]}>
        <Grid container direction='column' spacing={2} className={classes.container}>
          <Grid item>
            <Grid container className={classes.headerText}>
              {fields.map((item, index) => (
                <Grid item xs={2} key={index} className={classes.secondaryText}>
                  {item.label}
                </Grid>
              ))}
            </Grid>
          </Grid>
          {pricePrediction && (
            <Grid item>
              <Grid container justifyContent='space-between'>
                {fields.map((item, index) => (
                  <Grid item xs={2} key={index}>
                    {formatValue(pricePrediction[item.field], item.field === 'markup_percentage_total')}
                  </Grid>
                ))}
              </Grid>
            </Grid>)}
        </Grid>
        <SadStates states={[
          {
            when: isLoading,
            render: () => <WidgetLoadingState/>
          },
        ]}>
        </SadStates>
      </SadStates>
    </CollapsibleWidget>
  );
};