import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  rowContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '2rem',
    justifyContent: 'center',
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  uploadContainer: {
    padding: '1.125rem',
  },
}));