export enum RULE_CONTEXT {
  MAX_BUY_PRICE_PREDICTION = 'max_buy_price_prediction',
  QUOTE_PRICE_PREDICTION = 'quote_price_prediction'
}

export const RULE_CONTEXT_LABELS: Record<string, string> = {
  [RULE_CONTEXT.MAX_BUY_PRICE_PREDICTION]: 'Max Buy rules',
  [RULE_CONTEXT.QUOTE_PRICE_PREDICTION]: 'Quoting rules',
};

export enum RULE_ENGINE_EXECUTION_STATUS {
  SUCCESS = 'success',
  FAILURE = 'failure',
  SUCCESS_NO_DATA = 'success-no-data',
  FAILURE_INVALID_INPUT = 'failure-invalid-input',
}

export enum MODAL_CONTENT_TYPE {
  TABLE,
  RULE,
}
