import React, { useState } from 'react';
import clsx from 'clsx';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, Collapse, Grid, Typography } from '@material-ui/core';

import { useStyles } from './CollapsibleWidget.css';

type CollapsibleWidgetProps = {
  headerTitle: string | React.ReactNode,
  children: React.ReactNode,
  isClosed?: boolean,
};

export const CollapsibleWidget = ({ headerTitle, children, isClosed = false } : CollapsibleWidgetProps) => {
  const classes = useStyles();

  const [ isCollapsed, setIsCollapsed ] = useState<boolean>(isClosed);

  return (
    <Card className={classes.card}>
      <Grid container spacing={2} className={classes.collapseHeader} onClick={() => setIsCollapsed(!isCollapsed)}>
        <Grid item>
          <Typography variant='h4' className={classes.cardTitle}>
            { headerTitle }
          </Typography>
        </Grid>
        <Grid item>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={clsx(classes.arrowBase, isCollapsed ? classes.arrowUp : classes.arrowDown)}/>
        </Grid>
      </Grid>
      <Collapse in={!isCollapsed} timeout='auto' unmountOnExit>
        <Box className={classes.cardContent}>
          { children }
        </Box>
      </Collapse>
    </Card>
  );
};