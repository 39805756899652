import React, { useEffect, useRef, useState } from 'react';
import { setHours } from 'date-fns';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box, Grid, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { AccessControl, CollapsibleWidget, Layout, NavigationButton, Notes, PageLoadingState, SadStates, WidgetNoResultsState } from 'components';
import { useAuth, RuleExecutionLogProvider } from 'contexts';
import { EQUIPMENT_LABEL, NO_NOTES_TEXT, QUOTE_STATUS } from 'enums';
import { QuoteHistory, RuleExecutionLogWidget } from 'pages';
import { CUSTOMER_PERMISSIONS, QUOTE_PERMISSIONS } from 'permissions';
import {
  useGetGsTokenQuery,
  useQuoteQuery,
  useQuoteUpdateNotesMutation
} from 'services';
import { Params, SoftmodalResponse, StoppingPoint } from 'types';

import { CarrierPreference } from './components/CarrierPreference/CarrierPreference';
import { PricePrediction } from './components/PricePrediction/PricePrediction';
import { QuoteActivity } from './components/QuoteActivity';
import { QuoteDetails } from './components/QuoteDetails/QuoteDetails';
import { DryVanRateDetails } from './components/SoftmodalRates/DryVanRateDetails';
import { IntermodalRateDetails } from './components/SoftmodalRates/IntermodalRateDetails';

import { PredictionRateDetails } from './PredictionRateDetails';
import { QuoteActions } from './QuoteActions';

import { useStyles } from './QuoteDetailsPage.css';

type ContainerType = {
  setAccessTokenProvider(getToken: () => string): ContainerType;
  setUser(userInfo: { email: string; }): ContainerType;
  render(details: {
    truck: string;
    origin: { city: string; state: string; zip: string; };
    destination: { city: string; state: string; zip: string; };
    pickupDate: Date;
    weight: number;
    commodity: string;
    currency: string;
  }): unknown;
}

const gsRates = window.Greenscreens?.Rates?.build({
  baseApiPath: window.REACT_APP_GS_API
});

export const QuoteDetailPage = () => {
  const { id } = useParams<Params>();
  const { currentUser } = useAuth();

  const classes = useStyles();
  const queryClient = useQueryClient();

  const { data: tokenData, refetch: refetchGsToken } = useGetGsTokenQuery();
  const { data: gsToken } = tokenData || {};
  const { isLoading: isQuoteLoading, data: quoteData, error: quoteError, isFetching, refetch: refetchQuote } = useQuoteQuery(Number(id));
  const { data: quote = null } = quoteData || {};

  const { mutate: saveNotes, isLoading } = useQuoteUpdateNotesMutation(queryClient, {
    onSuccess: () => {
      toast.success('Quote notes updated');
      setIsEditableQuoteNotes(false);
    }
  });

  const [ quoteNotes, setQuoteNotes ] = useState<string>(quote?.notes || NO_NOTES_TEXT);
  const [ isEditableQuoteNotes, setIsEditableQuoteNotes ] = useState<boolean>(false);

  useEffect(() => {
    if (quote) {
      refetchGsToken();
      setQuoteNotes(quote?.notes || NO_NOTES_TEXT);
    }
  }, [quote]);

  const gsBoxRef = useRef(null);

  useEffect(() => {
    if (gsToken && quote && gsBoxRef.current) {
      let truck;
      switch (quote.equipment_type) {
        case EQUIPMENT_LABEL.DRY_VAN:
        case EQUIPMENT_LABEL.INTERMODAL:
        case EQUIPMENT_LABEL.POWER_ONLY:
          truck = 'Van';
          break;
        case EQUIPMENT_LABEL.FLATBED:
        case EQUIPMENT_LABEL.STEP_DECK:
          truck = EQUIPMENT_LABEL.FLATBED;
          break;
        case EQUIPMENT_LABEL.REEFER:
          truck = EQUIPMENT_LABEL.REEFER;
          break;
        default:
          truck = quote.equipment_name;
          break;
      }

      const isCanadianShortPostalCode = (location: StoppingPoint['location']) => {
        return location.country === 'CA' && location.postal_code.length === 3;
      };

      const formatQuoteStops = () => {
        const formattedStops = quote.lane.stops.map((stop: StoppingPoint) => {
          return {
            ...(!isCanadianShortPostalCode(stop.location)) && {zip: stop.location.postal_code},
            city: stop.location.city,
            state: stop.location.state,
          };
        });
        if (quote?.custom_fields?.nearest_delivery) {
          const nearest_delivery = quote?.custom_fields?.nearest_delivery as StoppingPoint['location'];
          formattedStops[formattedStops.length-1] = {
            ...(!isCanadianShortPostalCode(nearest_delivery)) && {zip: nearest_delivery.postal_code},
            city: nearest_delivery.city,
            state: nearest_delivery.state,
          };
        }
        return formattedStops;
      };

      const formattedStops = formatQuoteStops();

      if (gsRates && [ QUOTE_STATUS.OPEN, QUOTE_STATUS.RESPONDED ].includes(quote?.status)) {
        (gsRates.setContainer(gsBoxRef.current) as ContainerType)
          .setAccessTokenProvider(() => `Bearer ${gsToken}`)
          .setUser({
            email: currentUser.email
          })
          .render({
            truck: truck.toUpperCase(),
            origin: formattedStops[0],
            destination: formattedStops[formattedStops.length - 1],
            pickupDate: setHours(new Date(quote.lane.stops[0].date), 12),
            weight: quote.weight || 0,
            commodity: quote.commodity || '',
            currency: quote.currency,
          });
      }
    }
  }, [ gsToken, gsBoxRef ]);

  const handleSaveNotes = (quoteNotes: string) => {
    setQuoteNotes(quoteNotes);
    saveNotes({id: quote?.id, notes: quoteNotes});
  };

  return (
    <Layout title={<NavigationButton size='small' content='Dashboard' urlTo='/dashboard'/>}>
      <SadStates states={[
        {
          when: isQuoteLoading || isFetching,
          render: () => <PageLoadingState/>
        },
        {
          when: quoteError?.request.status === 404,
          render: function renderNotFoundState () {
            return <h1>It seems that quote you are looking for does not exist</h1>;
          }
        }
      ]}>
        <QuoteDetails quote={quote} refetchQuote={refetchQuote}/>
        <Grid container direction='column' spacing={4} wrap='nowrap'>
          <Grid item>
            <RuleExecutionLogProvider>
              <QuoteActions quote={quote}/>
            </RuleExecutionLogProvider>
          </Grid>
          <Grid item>
            <Grid container spacing={4}>
              <Grid item xs={8}>
                <Grid container direction='column' spacing={4} wrap='nowrap'>
                  {quote?.custom_fields?.softmodal && (
                    <Grid item>
                      {quote?.equipment_type === EQUIPMENT_LABEL.INTERMODAL && (
                        <IntermodalRateDetails data={quote?.custom_fields?.softmodal as SoftmodalResponse} currency={quote.currency} />
                      )}
                      {quote?.equipment_type === EQUIPMENT_LABEL.DRY_VAN && (
                        <DryVanRateDetails data={quote?.custom_fields?.softmodal as SoftmodalResponse} currency={quote.currency} />
                      )}
                    </Grid>
                  )}
                  {([ QUOTE_STATUS.WON, QUOTE_STATUS.LOST, QUOTE_STATUS.RESPONDED ].includes(quote?.status) || (quote?.status === QUOTE_STATUS.OPEN && quote.lane.stops.length > 2)) &&
                    <Grid item>
                      <Card className={classes.cardBorder}>
                        <PredictionRateDetails predictionRate={quote.gs_rate} distance={quote.distance} currency={quote.currency}/>
                      </Card>
                    </Grid>
                  }
                  {[ QUOTE_STATUS.OPEN, QUOTE_STATUS.RESPONDED ].includes(quote?.status) &&
                    <Grid item>
                      <Card className={classes.cardBorder}>
                        <CardContent>
                          <Typography variant='h4' className={classes.cardTitle}>
                            Greenscreens Predictions
                          </Typography>
                        </CardContent>
                        {gsRates ?
                          <CardContent ref={gsBoxRef}/> :
                          <Box className={classes.cardContent}>
                            <WidgetNoResultsState message='The Greenscreens widget is currently unavailable.'/>
                          </Box>
                        }
                      </Card>
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item xs={4} style={{maxWidth: '1/3'}}>
                <Grid container direction='column' spacing={4} wrap='nowrap'>
                  <AccessControl permissions={[QUOTE_PERMISSIONS.RETRIEVE]}>
                    <Grid item>
                      <PricePrediction currency={quote?.currency} />
                    </Grid>
                  </AccessControl>
                  {quote?.status === QUOTE_STATUS.OPEN && (
                    <>
                      <AccessControl permissions={[QUOTE_PERMISSIONS.RETRIEVE]}>
                        <Grid item>
                          <QuoteHistory customer={quote?.customer} pickupZip={quote?.lane?.stops[0].location.postal_code} deliveryZip={quote?.lane?.stops[quote.lane.stops.length-1].location.postal_code} />
                        </Grid>
                      </AccessControl>
                    </>
                  )}
                  <Grid item>
                    <CarrierPreference/>
                  </Grid>
                  <AccessControl permissions={[CUSTOMER_PERMISSIONS.PRICE_RULES_RETRIEVE]}>
                    <Grid item>
                      <RuleExecutionLogProvider>
                        <RuleExecutionLogWidget rulesetExecutionLogs={quote?.ruleset_execution_logs || null}/>
                      </RuleExecutionLogProvider>
                    </Grid>
                  </AccessControl>
                  <Grid item>
                    <CollapsibleWidget headerTitle = 'Activity log'>
                      <AccessControl permissions={[QUOTE_PERMISSIONS.RETRIEVE]}>
                        <QuoteActivity quote={quote}/>
                      </AccessControl>
                    </CollapsibleWidget>
                  </Grid>
                  <Grid item>
                    <Notes
                      variant='yellow'
                      headerTitle='Quote Notes'
                      defaultNotes={quoteNotes}
                      isEditable={isEditableQuoteNotes}
                      setIsEditable={setIsEditableQuoteNotes}
                      onSave={(notes: string) => handleSaveNotes(notes)}
                      onSaveInProgress={isLoading}/>
                    <Notes
                      variant='purple'
                      headerTitle='Shipper Notes'
                      defaultNotes={quote?.customer_notes}
                      areNotesEditable={false}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SadStates>
    </Layout>
  );
};